import { singular } from 'pluralize';
import { FC, HTMLProps } from 'react';
import styled from 'styled-components';
import { TypeDefinitionsWrapper } from '../../../api-client';

export interface PropInfo {
    typeName: string;
    order: number;
}
/** Dictionary from property names to the name of their type and order. */
export type TypeDefinition = Record<string, PropInfo>;
interface PropertyBase {
    title: string;
    parentTitle: string | null;
    isLoop: boolean;
}
interface Property extends PropertyBase {
    path: string;
}

interface Branch extends PropertyBase {
    children: Node[];
}

type Node = Property | Branch;

const order = (x: Node) => ('children' in x ? 1 : x.isLoop ? -1 : 0);

function generatePropertyTreeType(
    propName: string,
    _typeName: string,
    { typeDefs, existing, openLoops, path: parentPath, searchTerm }: GeneratePropertyTreeProps,
    parentTitle: string | null,
): Node | null {
    const isLoop = _typeName.startsWith('[]');
    const path = (parentPath ? parentPath + '.' : '') + propName;
    const typeName = isLoop && openLoops && openLoops.has(path) ? _typeName.substr(2, _typeName.length) : _typeName;
    const propType = typeDefs.typeDefinitions[typeName];

    let title = propName.replace(/([a-z]+)([A-Z])/g, '$1 $2');
    if (isLoop) title = singular(title);

    if (existing && existing.includes(typeName)) {
        return null;
    } else if (propType) {
        const children = generatePropertyTree(
            {
                typeDefs: typeDefs,
                currentType: propType,
                openLoops: openLoops,
                path: path,
                existing: [...(existing || []), typeName],
                searchTerm: searchTerm,
            },
            title,
        );
        if (children.length > 0) {
            return {
                isLoop,
                title,
                parentTitle,
                children,
            };
        } else return null;
    } else {
        if (searchTerm && !title.toLowerCase().includes(searchTerm.toLowerCase())) {
            return null;
        } else {
            return {
                path: path,
                title,
                isLoop,
                parentTitle,
            };
        }
    }
}

/** Context for converting to a type tree TODO */
interface GeneratePropertyTreeProps {
    /** Dictionary from type names to their type definition. */
    typeDefs: TypeDefinitionsWrapper;
    /**  */
    currentType: TypeDefinition;
    /** TODO */
    openLoops?: Set<string>;
    /** contexts that are already in the tree. prevents duplication. */
    existing?: string[];
    path?: string;
    searchTerm?: string;
}
function generatePropertyTree(props: GeneratePropertyTreeProps, parentTitle: string | null): Node[] {
    const { currentType: context } = props;
    return Object.entries(context)
        .sort(([nameA, { order: orderA }], [nameB, { order: orderB }]) => orderA - orderB || nameA.localeCompare(nameB))
        .map(([propName, { typeName }]) => generatePropertyTreeType(propName, typeName, props, parentTitle))
        .filter((x): x is Node => !!x)
        .sort((a, b) => order(a) - order(b));
}

/** Context for converting to a type tree TODO */
export interface PropertyTreeProps extends GeneratePropertyTreeProps {
    onPropertyClick: (property: Property) => void;
}

export const PropertyTree: FC<PropertyTreeProps> = ({ onPropertyClick, ...props }) => {
    return (
        <>
            {generatePropertyTree(props, null).map((node) => (
                <PropertyTreeNode key={node.title} node={node} onPropertyClick={onPropertyClick} />
            ))}
        </>
    );
};

const Blockquote = styled.blockquote`
    margin-top: 0;
`;

interface PropertyTreeNodeProps {
    node: Node;
    onPropertyClick: (node: Property) => void;
}
export const PropertyTreeNode: FC<PropertyTreeNodeProps> = ({ node, onPropertyClick }) => {
    if ('children' in node) {
        return (
            <details>
                <summary>{node.title}</summary>
                <Blockquote>
                    {node.children.map((childNode) => (
                        <PropertyTreeNode key={childNode.title} node={childNode} onPropertyClick={onPropertyClick} />
                    ))}
                </Blockquote>
            </details>
        );
    } else {
        const { isLoop, path, title } = node;
        return (
            <button
                type="button"
                className={isLoop ? 'loop' : ''}
                key={path}
                onClick={(e) => {
                    e.preventDefault();
                    onPropertyClick(node);
                }}
            >
                {isLoop ? <i className="fa fa-list" /> : null}
                <span>{title}</span>
            </button>
        );
    }
};
