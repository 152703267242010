/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface Attendance {
    /** @format int32 */
    lessonID: number;

    /** @format int32 */
    studentID: number;
    statusID: AttendanceStatusEnum;
    lesson?: Lesson;
    student?: Student;
}

/**
 * @format int32
 */
export enum AttendanceStatusEnum {
    Present = 2,
    Late = 3,
    Absent = 4,
    Excused = 5,
    Vacation = 6,
}

export interface Class {
    /** @format int32 */
    id: number;

    /** @format int32 */
    yearID: number;

    /** @format int32 */
    semesterID: number;

    /** @format date-time */
    startDate?: string | null;

    /** @format int32 */
    count?: number | null;
    time: TimeSpan;

    /** @format int32 */
    length: number;

    /** @format int32 */
    teacherID: number;

    /** @format int32 */
    daysOfWeek: number;

    /** @format double */
    attendanceWeight: number;

    /** @format double */
    testWeight: number;

    /** @format double */
    homeworkWeight: number;

    /** @format double */
    credits: number;

    /** @format int32 */
    subjectID: number;
    typeID: ClassTypeEnum;
    passFailGrades: boolean;
    advancedSchedule: boolean;
    yearSemester?: YearSemester;
    teacher?: StaffMember;
    subject?: Subject;
    lessons?: Lesson[] | null;
    tracks?: ClassTrack[] | null;
    students?: StudentClass[] | null;
    attachments?: ClassAttachment[] | null;
    times?: ClassTime[] | null;
    homeworks?: ClassHomework[] | null;
    fullName?: string | null;
}

export interface ClassAttachment {
    /** @format int32 */
    id: number;

    /** @format int32 */
    classID: number;
    orgNameLink?: string | null;
    filename?: string | null;

    /** @format date-time */
    dateUploaded: string;

    /** @format int32 */
    lessonID?: number | null;
    note?: string | null;

    /** @format int32 */
    pinOrder?: number | null;
    title?: string | null;
    class?: Class;
    lesson?: Lesson;
}

export interface ClassHomework {
    /** @format int32 */
    id: number;

    /** @format int32 */
    classID: number;

    /** @format date-time */
    date: string;
    name?: string | null;
    description?: string | null;

    /** @format double */
    weight: number;
    class?: Class;
    studentGrades?: ClassHomeworkGrade[] | null;
}

export interface ClassHomeworkGrade {
    /** @format int32 */
    homeworkID: number;

    /** @format int32 */
    studentID: number;

    /** @format double */
    grade: number;
    homework?: ClassHomework;
    student?: Student;
}

export interface ClassTest {
    /** @format int32 */
    id: number;

    /** @format int32 */
    lessonID: number;
    name: string;
    description?: string | null;

    /** @format double */
    weight: number;
    lesson?: Lesson;
    grades?: ClassTestGrade[] | null;
}

export interface ClassTestGrade {
    /** @format int32 */
    testID: number;

    /** @format int32 */
    studentID: number;

    /** @format double */
    grade: number;
    test?: ClassTest;
    student?: Student;
}

export interface ClassTime {
    /** @format int32 */
    id: number;

    /** @format int32 */
    classID: number;

    /** @format int32 */
    dayOfWeek: number;
    time: TimeSpan;

    /** @format int32 */
    length: number;
    class?: Class;
}

export interface ClassTrack {
    /** @format int32 */
    classID: number;

    /** @format int32 */
    trackID: number;
    class?: Class;
    track?: Track;
}

/**
 * @format int32
 */
export enum ClassTypeEnum {
    AttendanceOnTranscript = 171,
    NoAttendanceOnTranscript = 172,
    NoAttendanceNoTranscript = 173,
    AttendanceNoTranscript = 185,
}

/**
 * @format int32
 */
export enum Currency {
    USD = 0,
}

export interface CustomField {
    /** @format int32 */
    id: number;

    /** @format int32 */
    sectionID: number;
    typeID: CustomFieldType;
    name?: string | null;
    required: boolean;

    /** @format int32 */
    orderBy: number;
    helpText?: string | null;
    formula?: string | null;
    section?: FieldSection;
    opts?: CustomFieldOpt[] | null;
}

export interface CustomFieldDTO {
    /** @format int32 */
    id: number;
    name?: string | null;
    type: CustomFieldType;
    options?: IDName[] | null;
    helpText?: string | null;
}

export interface CustomFieldOpt {
    /** @format int32 */
    id: number;

    /** @format int32 */
    fieldID: number;
    name?: string | null;

    /** @format int32 */
    orderBy: number;
    field?: CustomField;
}

/**
 * @format int32
 */
export enum CustomFieldType {
    TextField = 217,
    NotesField = 218,
    NumberField = 219,
    Dropdown = 220,
    MultiDropdown = 221,
    Radios = 222,
    DatePicker = 223,
    TimePicker = 224,
    Attachment = 225,
    Enrollment = 226,
    PhoneNums = 228,
    Emails = 230,
    Country = 231,
    State = 232,
    DecimalInput = 285,
    Toggle = 292,
    Formula = 294,
}

/**
 * @format int32
 */
export enum CustomListEnum {
    PhoneTypes = 227,
    EmailTypes = 229,
    StudentAccountStatuses = 284,
    EventTypes = 290,
    MealKinds = 291,
    EmailReplyTos = 293,
    InvoiceItems = 295,
    PaymentItems = 296,
    CreditItems = 297,
    PledgeItems = 298,
    DonationItems = 299,
}

export interface CustomListEnumCustomListItemListKeyValuePair {
    key: CustomListEnum;
    value: CustomListItem[];
}

export interface CustomListItem {
    /** @format int32 */
    id: number;

    /** @format int32 */
    schoolID: number;
    listID: CustomListEnum;
    name?: string | null;

    /** @format int32 */
    orderBy: number;
}

export interface CustomListItemUse {
    user: IDName;
    field: IDName;
    section: IDName;
    tab: IDName;
}

export interface CustomSectionDTO {
    name?: string | null;
    fields?: CustomFieldDTO[] | null;
    hasEditPermission: boolean;
}

export interface Dorm {
    /** @format int32 */
    id: number;

    /** @format int32 */
    schoolID: number;
    name?: string | null;

    /** @format int32 */
    capacity: number;

    /** @format int32 */
    trackID?: number | null;
    mixedTracks: boolean;
}

export interface ExpressionNode {
    typeName: string;
    literalValueJson?: string | null;
    methodName?: string | null;
    parameters?: ExpressionNode[] | null;
}

export interface FieldSection {
    /** @format int32 */
    id: number;

    /** @format int32 */
    tabID: number;
    name?: string | null;

    /** @format int32 */
    orderBy: number;
    inactive: boolean;
    tab?: FieldTab;
    fields?: CustomField[] | null;
    userGroups?: UserGroupFieldSection[] | null;
}

export interface FieldTab {
    /** @format int32 */
    id: number;

    /** @format int32 */
    schoolID: number;
    typeID?: FieldTabType;
    isStudent: boolean;
    name?: string | null;

    /** @format int32 */
    orderBy: number;
    isReadonly: boolean;
    userGroups?: UserGroupFieldTab[] | null;
    sections?: FieldSection[] | null;
}

/**
 * @format int32
 */
export enum FieldTabType {
    Finances = -16,
    Transcripts = -15,
    Meals = -14,
    StaffGeneral = -12,
    Payments = -10,
    Program = -6,
    Exemptions = -5,
    Classes = -4,
    General = -1,
}

export interface GradePercentage {
    /** @format int32 */
    schoolID: number;

    /** @format int32 */
    gradeID: number;

    /** @format double */
    percentage: number;
    school?: School;
}

export interface IDName {
    /** @format int32 */
    id: number;
    name?: string | null;
}

export interface Lesson {
    /** @format int32 */
    id: number;

    /** @format int32 */
    classID: number;

    /** @format date-time */
    dateTime: string;

    /** @format int32 */
    length?: number | null;

    /** @format int32 */
    teacherID?: number | null;
    cancelled: boolean;
    attendanceCompleted?: boolean | null;
    class?: Class;
    teacher?: StaffMember;
    attachments?: ClassAttachment[] | null;
    attendances?: Attendance[] | null;
    tests?: ClassTest[] | null;
}

export interface Lookup {
    /** @format int32 */
    id: number;
    typeID: LookupTypeEnum;
    value?: string | null;

    /** @format int32 */
    orderBy?: number | null;
    type?: LookupType;
}

export interface LookupType {
    id: LookupTypeEnum;
    name?: string | null;
}

/**
 * @format int32
 */
export enum LookupTypeEnum {
    MemberTypes = 1,
    AttendanceStatuses = 2,
    DatotStatuses = 4,
    PaymentGeneralStatuses = 7,
    PaymentStatuses = 8,
    PaymentNextSteps = 9,
    Currencies = 10,
    FinanceContactPeople = 12,
    Countries = 15,
    Grades = 19,
    ColorSchemes = 21,
    ClassTypes = 22,
    StudentTabs = 23,
    CustomFieldTypes = 27,
    CustomLists = 28,
    States = 29,
    EventPeriods = 30,
}

export interface LTGViewModel {
    /** @format int32 */
    transacteeId: number;
    filter?: ExpressionNode;
}

export interface MealAdditionalDay {
    /** @format int32 */
    id: number;

    /** @format int32 */
    kindID: number;

    /** @format date-time */
    date: string;
    kind?: CustomListItem;
}

export interface MealRegistration {
    /** @format int32 */
    id: number;

    /** @format int32 */
    studentID: number;

    /** @format date-time */
    fromDate?: string | null;

    /** @format date-time */
    toDate?: string | null;
    student?: Student;
    kinds?: MealRegistrationKind[] | null;
}

export interface MealRegistrationKind {
    /** @format int32 */
    registrationID: number;

    /** @format int32 */
    kindID: number;
    registration?: MealRegistration;
}

export interface Permission {
    id: PermissionEnum;
    name?: string | null;
    viewEdit: boolean;
    hideView: boolean;
}

/**
 * @format int32
 */
export enum PermissionEnum {
    ReportStudentAndStaff = 12,
    TeachersReport = 24,
    SchoolSettings = 25,
    SiteUserGroups = 27,
    MealReport = 32,
    ReportDailyMonthlyAnnualHonorRoll = 35,
    GradeReport = 36,
    AccessOtherClasses = 39,
    Attendance = 40,
    DataEntry = 41,
    DormsManager = 42,
    ReportApplications = 43,
    StudentAddDelete = 44,
    AccessOtherStaff = 45,
    StaffAddDelete = 46,
    Email = 47,
    TranscriptTemplates = 48,
    CanViewClasses = -4,
    StaffList = -3,
    StudentList = -1,
}

export interface ProblemDetails {
    type?: string | null;
    title?: string | null;

    /** @format int32 */
    status?: number | null;
    detail?: string | null;
    instance?: string | null;
}

export interface PropInfo {
    typeName: string;

    /** @format int32 */
    order: number;
}

export interface School {
    /** @format int32 */
    id: number;
    name: string;

    /** @format double */
    missingPercentA: number;

    /** @format double */
    missingPercentB: number;

    /** @format double */
    missingPercentC: number;

    /** @format double */
    missingPercentD: number;

    /**
     * @format int32
     * @min 1
     * @max 10
     */
    latesForAbsent?: number | null;

    /** @format double */
    attPercentPass: number;
    logo?: string | null;
    address1?: string | null;
    address2?: string | null;
    city?: string | null;
    state?: string | null;
    zip?: string | null;

    /** @format int32 */
    countryID?: number | null;
    email?: string | null;
    phone?: string | null;

    /** @format int32 */
    defaultClassLength: number;

    /** @format int32 */
    studentUserGroupID: number;

    /** @format int32 */
    defaultStaffUserGroupID: number;

    /** @format int32 */
    newApplicantUserGroupID: number;
    domain?: string | null;
    showApplicationsMenu: boolean;
    showDormsMenu: boolean;

    /** @format int32 */
    currentYearID?: number | null;

    /** @format date-time */
    fiscalYear: string;

    /** @format int32 */
    enrolledOptID?: number | null;

    /** @format int32 */
    newApplicantOptID?: number | null;

    /** @format int32 */
    studentDobFieldID?: number | null;

    /** @format int32 */
    studentIdentityFieldID?: number | null;
    allEmailBcc?: string | null;

    /** @format int32 */
    mealDaysOfWeek: number;
    smtpHost?: string | null;
    smtpUsername?: string | null;
    smtpPassword?: string | null;
    smtpFrom?: string | null;
    featureEmails: boolean;
    featureExport: boolean;

    /** @format int32 */
    limitEmailTemplates?: number | null;

    /** @format int32 */
    limitStudentCustomFields?: number | null;

    /** @format int32 */
    limitStaffCustomFields?: number | null;

    /** @format int32 */
    limitUserGroups?: number | null;

    /** @format int32 */
    limitStudentCustomReports?: number | null;

    /** @format int32 */
    limitStaffCustomReports?: number | null;

    /** @format int32 */
    limitEmailTmpls?: number | null;

    /** @format int32 */
    limitStudents?: number | null;
    enrolledOpt?: CustomFieldOpt;
    newApplicantOpt?: CustomFieldOpt;
    tracks?: Track[] | null;
    users?: User[] | null;
    years?: Year[] | null;
    semesters?: Semester[] | null;
    gradePercentages?: GradePercentage[] | null;
    dorms?: Dorm[] | null;
}

export interface Semester {
    /** @format int32 */
    id: number;

    /** @format int32 */
    schoolID: number;
    name?: string | null;

    /** @format int32 */
    orderBy: number;
    school?: School;
}

export interface SettingsCustomizeCustomListData {
    listID: CustomListEnum;
    data?: string | null;
}

export interface SettingsCustomizeModel {
    customListItems?: CustomListEnumCustomListItemListKeyValuePair[] | null;
    mealKinds?: CustomListItem[] | null;
    studentCustomFields?: IDName[] | null;
    years?: Year[] | null;
    userGroups?: UserGroup[] | null;
    overbookedYearIDs?: number[] | null;
    school?: School;
    customListsData?: SettingsCustomizeCustomListData[] | null;
    mealDaysOfWeek?: number[] | null;
    mealAdditionalDays?: MealAdditionalDay[] | null;
}

export interface StaffMember {
    /** @format int32 */
    id: number;

    /** @format int32 */
    userGroupID: number;
    isSuperadmin: boolean;
    isTeacher: boolean;

    /** @format date-time */
    dateJoined: string;

    /** @format date-time */
    dateResigned?: string | null;
    user?: User;
    userGroup?: UserGroup;
    classes?: Class[] | null;
    fullName?: string | null;
}

export interface Student {
    /** @format int32 */
    id: number;

    /** @format int32 */
    trackID: number;

    /** @format int32 */
    enrollmentID: number;

    /** @format int32 */
    paymentGeneralStatusID: number;
    inDorm: boolean;

    /** @format int32 */
    dormID?: number | null;
    prevDorms?: string | null;
    transcriptUploads?: string | null;

    /** @format date-time */
    transcriptExported?: string | null;
    newApplicant: boolean;
    user?: User;
    track?: Track;
    dorm?: Dorm;
    classes?: StudentClass[] | null;
    yearSemesters?: StudentYearSemester[] | null;
    exemptions?: StudentExemption[] | null;
    attendances?: Attendance[] | null;
    homeworkGrades?: ClassHomeworkGrade[] | null;
    mealRegistrations?: MealRegistration[] | null;
    transcripts?: StudentTranscript[] | null;
}

export interface StudentClass {
    /** @format int32 */
    studentID: number;

    /** @format int32 */
    classID: number;

    /** @format double */
    grade?: number | null;
    passed?: boolean | null;
    student?: Student;
    class?: Class;
}

export interface StudentExemption {
    /** @format int32 */
    id: number;

    /** @format int32 */
    studentID: number;

    /** @format date-time */
    fromDate: string;

    /** @format date-time */
    toDate: string;
    description: string;
    isVacation: boolean;

    /** @format int32 */
    daysOfWeek: number;
    student?: Student;
    classes?: StudentExemptionClass[] | null;
}

export interface StudentExemptionClass {
    /** @format int32 */
    exemptionID: number;

    /** @format int32 */
    classID: number;
    exemption?: StudentExemption;
    class?: Class;
}

export interface StudentTranscript {
    /** @format int32 */
    studentID: number;
    name: string;
    filename: string;

    /** @format date-time */
    issueDate: string;

    /** @format int32 */
    templateID?: number | null;
    templateName?: string | null;
    templateType?: TemplateType;
    student?: Student;
    template?: Template;
}

export interface StudentTranscriptsIndexViewModel {
    studentTranscripts: StudentTranscriptViewModel[];
    hasEditPermission: boolean;
    hasEmailFeature: boolean;
    sections: CustomSectionDTO[];
    customFieldValues: Record<string, string>;
}

export interface StudentTranscriptViewModel {
    /** @format int32 */
    studentID: number;
    name: string;
    filename: string;

    /** @format date-time */
    issueDate: string;
    template?: IDName;
    templateType?: IDName;
}

export interface StudentYearSemester {
    /** @format int32 */
    studentID: number;

    /** @format int32 */
    yearID: number;

    /** @format int32 */
    semesterID: number;
    student?: Student;
    yearSemester?: YearSemester;
}

export interface Subject {
    /** @format int32 */
    id: number;

    /** @format int32 */
    schoolID: number;
    name?: string | null;
    classes?: Class[] | null;
}

export interface Template {
    /** @format int32 */
    id: number;

    /** @format int32 */
    schoolID?: number | null;
    name?: string | null;
    body?: string | null;
    type?: TemplateType;
    thumbnail?: string | null;

    /**
     * @format float
     * @min 1
     * @max 2147483647
     */
    pageWidth: number;

    /**
     * @format float
     * @min 1
     * @max 2147483647
     */
    pageHeight: number;
    isFavorite: boolean;
    templateTracks?: TemplateTrack[] | null;
}

export interface TemplateDescription {
    /** @format int32 */
    id: number;
    name?: string | null;
    type?: TemplateType;
}

export interface TemplateDetailsDTO {
    template: TemplateDTO;
    tracks: IDName[];
    selectedTracks: number[];
    templateBody: string;
    availableProperties: TypeDefinitionsWrapper;
}

export interface TemplateDTO {
    /** @format int32 */
    id: number;
    name: string;
    body: string;

    /** @format int32 */
    schoolID?: number | null;
    type?: TemplateType;
    thumbnail: string;

    /** @format float */
    pageWidth: number;

    /** @format float */
    pageHeight: number;
}

export interface TemplateIndexDTO {
    /** @format int32 */
    id: number;
    name: string;
    thumbnail: string;
    isFavorite: boolean;
}

export interface TemplatesIndexModel {
    presets: TemplateIndexDTO[];
    templates: TemplateIndexDTO[];
}

export interface TemplateTrack {
    /** @format int32 */
    templateID: number;

    /** @format int32 */
    trackID: number;
    track?: Track;
    template?: Template;
}

/**
 * @format int32
 */
export enum TemplateType {
    MultiYear = 1,
    SingleYear = 2,
}

export interface TimeSpan {
    /** @format int64 */
    ticks: number;

    /** @format int32 */
    days: number;

    /** @format int32 */
    hours: number;

    /** @format int32 */
    milliseconds: number;

    /** @format int32 */
    minutes: number;

    /** @format int32 */
    seconds: number;

    /** @format double */
    totalDays: number;

    /** @format double */
    totalHours: number;

    /** @format double */
    totalMilliseconds: number;

    /** @format double */
    totalMinutes: number;

    /** @format double */
    totalSeconds: number;
}

export interface Track {
    /** @format int32 */
    id: number;

    /** @format int32 */
    schoolID: number;
    name: string;

    /** @format int32 */
    orderBy: number;

    /** @format int32 */
    classCapacity: number;
    image?: string | null;

    /** @format int32 */
    dormCapacity: number;
    shortName: string;
    school?: School;
    yearSemesterTracks?: YearSemesterTrack[] | null;
    classes?: ClassTrack[] | null;
}

export interface Transaction {
    /** @format int32 */
    groupId: number;

    /** @format int32 */
    commodityId?: number | null;

    /** @format double */
    commodityValue?: number | null;

    /** @format double */
    quantity: number;

    /** @format int32 */
    id: number;
    group?: TransactionGroup;
    commodity?: CustomListItem;
}

export interface TransactionGroup {
    /** @format int32 */
    schoolId: number;

    /** @format int32 */
    id: number;
    transactionType: TransactionType;

    /** @format int32 */
    payingForInvoiceId?: number | null;
    notes?: string | null;

    /** @format date-time */
    entryDate: string;

    /** @format date-time */
    dueDate: string;

    /** @format int32 */
    transactorId: number;

    /** @format int32 */
    transacteeId: number;

    /** @format int32 */
    requestedPaymentCommodityId?: number | null;
    currency: Currency;
    statusDetails?: string | null;
    status?: TransactionStatus;

    /** @format double */
    amount?: number | null;
    payingForInvoice?: TransactionGroup;
    payments?: TransactionGroup[] | null;
    transactor?: User;
    transactee?: User;
    requestedPaymentCommodity?: CustomListItem;
    items?: Transaction[] | null;
}

export interface TransactionGroupViewModel {
    /** @format int32 */
    id: number;
    transactionType: TransactionType;

    /** @format int32 */
    payingForInvoiceId?: number | null;
    notes?: string | null;

    /** @format date-time */
    entryDate: string;

    /** @format date-time */
    dueDate: string;
    transactor: IDName;
    transactee: IDName;

    /** @format int32 */
    requestedPaymentCommodityId?: number | null;
    currency: Currency;
    statusDetails?: string | null;
    items: TransactionViewModel[];
}

/**
 * @format int32
 */
export enum TransactionStatus {
    Pending = 0,
    Success = 1,
    Failure = 2,
}

/**
 * @format int32
 */
export enum TransactionType {
    Invoice = 0,
    Payment = 1,
    Credit = 2,
    Pledge = 3,
    Donation = 4,
}

export interface TransactionViewModel {
    /** @format int32 */
    id: number;
    commodity?: IDName;

    /** @format double */
    commodityValue?: number | null;

    /** @format double */
    quantity: number;
}

export interface TypeDefinitionsWrapper {
    typeDefinitions: Record<string, Record<string, PropInfo>>;
}

export interface User {
    /** @format int32 */
    id: number;

    /** @format int32 */
    schoolID: number;
    email?: string | null;

    /** @format byte */
    password?: string | null;
    isStudent: boolean;
    title?: string | null;
    firstName: string;
    lastName: string;
    schemeID: UserScheme;
    image?: string | null;
    school?: School;
    staffMember?: StaffMember;
    student?: Student;
    customFields?: UserCustomField[] | null;
    firstLastName?: string | null;
    isSuperadmin: boolean;
}

export interface UserCustomField {
    /** @format int32 */
    userID: number;

    /** @format int32 */
    fieldID: number;
    val?: string | null;
    user?: User;
    field?: CustomField;
}

export interface UserGroup {
    /** @format int32 */
    id: number;

    /** @format int32 */
    schoolID: number;
    name: string;
    school?: School;
    permissions?: UserGroupPermission[] | null;
    tabPermissions?: UserGroupFieldTab[] | null;
    sectionPermissions?: UserGroupFieldSection[] | null;
}

export interface UserGroupFieldSection {
    /** @format int32 */
    userGroupID: number;

    /** @format int32 */
    fieldSectionID: number;
    canEdit: boolean;
    userGroup?: UserGroup;
    fieldSection?: FieldSection;
}

export interface UserGroupFieldTab {
    /** @format int32 */
    userGroupID: number;

    /** @format int32 */
    fieldTabID: number;
    canEdit: boolean;
    fieldTab?: FieldTab;
}

export interface UserGroupPermission {
    /** @format int32 */
    groupID: number;
    permissionID: PermissionEnum;
    canEdit: boolean;
    group?: UserGroup;
    permission?: Permission;
}

/**
 * @format int32
 */
export enum UserScheme {
    Gray = 168,
    Pink = 169,
}

export interface Year {
    /** @format int32 */
    id: number;

    /** @format int32 */
    schoolID: number;
    name: string;

    /** @format date-time */
    startDate: string;

    /** @format date-time */
    endDate: string;
    semesters: YearSemester[];
    events: YearEvent[];
}

export interface YearEvent {
    /** @format int32 */
    id: number;

    /** @format int32 */
    yearID: number;

    /** @format int32 */
    typeID: number;
    description: string;

    /** @format date-time */
    fromDate: string;

    /** @format date-time */
    toDate: string;
    inSchool: boolean;

    /** @format int32 */
    creatorID: number;
    periodID: YearEventPeriod;
    year?: Year;
    creator?: StaffMember;
    tracks?: YearEventTrack[] | null;
    cancelledMeals?: YearEventCancelledMeal[] | null;
}

export interface YearEventCancelledMeal {
    /** @format int32 */
    eventID: number;

    /** @format int32 */
    mealID: number;
    event?: YearEvent;
    meal?: CustomListItem;
}

/**
 * @format int32
 */
export enum YearEventPeriod {
    PartDay = 286,
    AllDay = 287,
    MultiDay = 288,
}

export interface YearEventTrack {
    /** @format int32 */
    eventID: number;

    /** @format int32 */
    trackID: number;
    event?: YearEvent;
    track?: Track;
}

export interface YearSemester {
    /** @format int32 */
    yearID: number;

    /** @format int32 */
    semesterID: number;

    /** @format date-time */
    startDate: string;

    /** @format date-time */
    endDate: string;

    /** @format date-time */
    kicksInDate?: string | null;
    year?: Year;
    semester?: Semester;
    tracks?: YearSemesterTrack[] | null;
    students?: StudentYearSemester[] | null;
    fullName?: string | null;
}

export interface YearSemesterTrack {
    /** @format int32 */
    yearID: number;

    /** @format int32 */
    semesterID: number;

    /** @format int32 */
    trackID: number;
    yearSemester?: YearSemester;
}

export type QueryParamsType = Record<string | number, any>;
export type ResponseFormat = keyof Omit<Body, 'body' | 'bodyUsed'>;

export interface FullRequestParams extends Omit<RequestInit, 'body'> {
    /** set parameter to `true` for call `securityWorker` for this request */
    secure?: boolean;
    /** request path */
    path: string;
    /** content type of request body */
    type?: ContentType;
    /** query params */
    query?: QueryParamsType;
    /** format of response (i.e. response.json() -> format: "json") */
    format?: ResponseFormat;
    /** request body */
    body?: unknown;
    /** base url */
    baseUrl?: string;
    /** request cancellation token */
    cancelToken?: CancelToken;
}

export type RequestParams = Omit<FullRequestParams, 'body' | 'method' | 'query' | 'path'>;

export interface ApiConfig<SecurityDataType = unknown> {
    baseUrl?: string;
    baseApiParams?: Omit<RequestParams, 'baseUrl' | 'cancelToken' | 'signal'>;
    securityWorker?: (securityData: SecurityDataType | null) => Promise<RequestParams | void> | RequestParams | void;
    customFetch?: typeof fetch;
}

export interface HttpResponse<D extends unknown, E extends unknown = unknown> extends Response {
    data: D;
    error: E;
}

type CancelToken = Symbol | string | number;

export enum ContentType {
    Json = 'application/json',
    FormData = 'multipart/form-data',
    UrlEncoded = 'application/x-www-form-urlencoded',
}

export class HttpClient<SecurityDataType = unknown> {
    public baseUrl: string = '';
    private securityData: SecurityDataType | null = null;
    private securityWorker?: ApiConfig<SecurityDataType>['securityWorker'];
    private abortControllers = new Map<CancelToken, AbortController>();
    private customFetch = (...fetchParams: Parameters<typeof fetch>) => fetch(...fetchParams);

    private baseApiParams: RequestParams = {
        credentials: 'same-origin',
        headers: {},
        redirect: 'follow',
        referrerPolicy: 'no-referrer',
    };

    constructor(apiConfig: ApiConfig<SecurityDataType> = {}) {
        Object.assign(this, apiConfig);
    }

    public setSecurityData = (data: SecurityDataType | null) => {
        this.securityData = data;
    };

    private encodeQueryParam(key: string, value: any) {
        const encodedKey = encodeURIComponent(key);
        return `${encodedKey}=${encodeURIComponent(typeof value === 'number' ? value : `${value}`)}`;
    }

    private addQueryParam(query: QueryParamsType, key: string) {
        return this.encodeQueryParam(key, query[key]);
    }

    private addArrayQueryParam(query: QueryParamsType, key: string) {
        const value = query[key];
        return value.map((v: any) => this.encodeQueryParam(key, v)).join('&');
    }

    protected toQueryString(rawQuery?: QueryParamsType): string {
        const query = rawQuery || {};
        const keys = Object.keys(query).filter((key) => 'undefined' !== typeof query[key]);
        return keys
            .map((key) =>
                Array.isArray(query[key]) ? this.addArrayQueryParam(query, key) : this.addQueryParam(query, key),
            )
            .join('&');
    }

    protected addQueryParams(rawQuery?: QueryParamsType): string {
        const queryString = this.toQueryString(rawQuery);
        return queryString ? `?${queryString}` : '';
    }

    private contentFormatters: Record<ContentType, (input: any) => any> = {
        [ContentType.Json]: (input: any) =>
            input !== null && (typeof input === 'object' || typeof input === 'string') ? JSON.stringify(input) : input,
        [ContentType.FormData]: (input: any) =>
            Object.keys(input || {}).reduce((formData, key) => {
                const property = input[key];
                formData.append(
                    key,
                    property instanceof Blob
                        ? property
                        : typeof property === 'object' && property !== null
                        ? JSON.stringify(property)
                        : `${property}`,
                );
                return formData;
            }, new FormData()),
        [ContentType.UrlEncoded]: (input: any) => this.toQueryString(input),
    };

    private mergeRequestParams(params1: RequestParams, params2?: RequestParams): RequestParams {
        return {
            ...this.baseApiParams,
            ...params1,
            ...(params2 || {}),
            headers: {
                ...(this.baseApiParams.headers || {}),
                ...(params1.headers || {}),
                ...((params2 && params2.headers) || {}),
            },
        };
    }

    private createAbortSignal = (cancelToken: CancelToken): AbortSignal | undefined => {
        if (this.abortControllers.has(cancelToken)) {
            const abortController = this.abortControllers.get(cancelToken);
            if (abortController) {
                return abortController.signal;
            }
            return void 0;
        }

        const abortController = new AbortController();
        this.abortControllers.set(cancelToken, abortController);
        return abortController.signal;
    };

    public abortRequest = (cancelToken: CancelToken) => {
        const abortController = this.abortControllers.get(cancelToken);

        if (abortController) {
            abortController.abort();
            this.abortControllers.delete(cancelToken);
        }
    };

    public request = async <T = any, E = any>({
        body,
        secure,
        path,
        type,
        query,
        format,
        baseUrl,
        cancelToken,
        ...params
    }: FullRequestParams): Promise<HttpResponse<T, E>> => {
        const secureParams =
            ((typeof secure === 'boolean' ? secure : this.baseApiParams.secure) &&
                this.securityWorker &&
                (await this.securityWorker(this.securityData))) ||
            {};
        const requestParams = this.mergeRequestParams(params, secureParams);
        const queryString = query && this.toQueryString(query);
        const payloadFormatter = this.contentFormatters[type || ContentType.Json];
        const responseFormat = format || requestParams.format;

        return this.customFetch(`${baseUrl || this.baseUrl || ''}${path}${queryString ? `?${queryString}` : ''}`, {
            ...requestParams,
            headers: {
                ...(type && type !== ContentType.FormData ? { 'Content-Type': type } : {}),
                ...(requestParams.headers || {}),
            },
            signal: cancelToken ? this.createAbortSignal(cancelToken) : void 0,
            body: typeof body === 'undefined' || body === null ? null : payloadFormatter(body),
        }).then(async (response) => {
            const r = response as HttpResponse<T, E>;
            r.data = null as unknown as T;
            r.error = null as unknown as E;

            const data = !responseFormat
                ? r
                : await response[responseFormat]()
                      .then((data) => {
                          if (r.ok) {
                              r.data = data;
                          } else {
                              r.error = data;
                          }
                          return r;
                      })
                      .catch((e) => {
                          r.error = e;
                          return r;
                      });

            if (cancelToken) {
                this.abortControllers.delete(cancelToken);
            }

            if (!response.ok) throw data;
            return data;
        });
    };
}

/**
 * @title SchoolEdTech
 * @version v1
 */
export class Api<SecurityDataType extends unknown> extends HttpClient<SecurityDataType> {
    lookup = {
        /**
         * No description
         *
         * @tags Lookup
         * @name GetLookup
         * @request GET:/GetLookup
         */
        getLookup: (query?: { type?: LookupTypeEnum }, params: RequestParams = {}) =>
            this.request<Lookup[], void>({
                path: `/GetLookup`,
                method: 'GET',
                query: query,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags Lookup
         * @name GetCustomList
         * @request GET:/GetCustomList
         */
        getCustomList: (query?: { customList?: CustomListEnum }, params: RequestParams = {}) =>
            this.request<CustomListItem[], void>({
                path: `/GetCustomList`,
                method: 'GET',
                query: query,
                format: 'json',
                ...params,
            }),
    };
    settings = {
        /**
         * No description
         *
         * @tags Settings
         * @name Customize
         * @request GET:/api/Settings/customize
         */
        customize: (params: RequestParams = {}) =>
            this.request<SettingsCustomizeModel, void>({
                path: `/Settings/customizeApi`,
                method: 'GET',
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags Settings
         * @name Customize2
         * @request POST:/api/Settings/customize
         * @originalName customize
         * @duplicate
         */
        customize2: (data: SettingsCustomizeModel, params: RequestParams = {}) =>
            this.request<void, void>({
                path: `/Settings/customizeApi`,
                method: 'POST',
                body: data,
                type: ContentType.Json,
                ...params,
            }),

        /**
         * No description
         *
         * @tags Settings
         * @name CustomListItemUses
         * @request GET:/api/Settings/isCustomListItemUsed
         */
        customListItemUses: (
            query: { fieldType: CustomFieldType; customLitItemId: number },
            params: RequestParams = {},
        ) =>
            this.request<CustomListItemUse[], void>({
                path: `/Settings/isCustomListItemUsed`,
                method: 'GET',
                query: query,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags Settings
         * @name Templates
         * @request GET:/api/Settings/templates
         */
        templates: (params: RequestParams = {}) =>
            this.request<TemplatesIndexModel, void>({
                path: `/Settings/templatesapi`,
                method: 'GET',
                format: 'json', 
                ...params,
            }),
    };
    students = {
        /**
         * No description
         *
         * @tags Students
         * @name Transcripts
         * @request GET:/api/Students/transcripts
         */
        transcripts: (query?: { studentId?: number }, params: RequestParams = {}) =>
            this.request<StudentTranscriptsIndexViewModel, void>({
                path: `/Students/transcriptsapi`,
                method: 'GET',
                query: query,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags Students
         * @name UploadTranscripts
         * @request POST:/api/Students/uploadTranscripts
         */
        uploadTranscripts: (data: { files?: File[] }, query?: { studentId?: number }, params: RequestParams = {}) =>
            this.request<void, void>({
                path: `/Students/uploadTranscriptsapi`,
                method: 'POST',
                query: query,
                body: data,
                type: ContentType.FormData,
                ...params,
            }),
    };
    templates = {
        /**
         * No description
         *
         * @tags Templates
         * @name StudentsYears
         * @request POST:/api/Templates/StudentsYears
         */
        studentsYears: (data: number[], params: RequestParams = {}) =>
            this.request<IDName[], void>({
                path: `/Templates/StudentsYearsApi`,
                method: 'POST',
                body: data,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags Templates
         * @name StudentTemplates
         * @request POST:/api/Templates/StudentTemplates
         */
        studentTemplates: (data: number[], params: RequestParams = {}) =>
            this.request<TemplateDescription[], void>({
                path: `/Templates/StudentTemplatesApi`,
                method: 'POST',
                body: data,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags Templates
         * @name SetFavorite
         * @request GET:/api/Templates/SetFavorite/{id}
         */
        setFavorite: (id: number, query?: { isFavorite?: boolean }, params: RequestParams = {}) =>
            this.request<void, void>({
                path: `/Templates/SetFavoriteApi/${id}`,
                method: 'GET',
                query: query,
                ...params,
            }),

        /**
         * No description
         *
         * @tags Templates
         * @name GetTemplate
         * @request GET:/api/Templates/GetTemplate/{id}
         */
        getTemplate: (id: number, query?: { cloneFrom?: number }, params: RequestParams = {}) =>
            this.request<TemplateDetailsDTO, void>({
                path: `/Templates/GetTemplateApi/${id}`,
                method: 'GET',
                query: query,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags Templates
         * @name Edit
         * @request POST:/api/Templates/Edit/{id}
         */
        edit: (
            id: string,
            data: {
                'Template.ID'?: number;
                'Template.SchoolID'?: number;
                'Template.Name'?: string;
                'Template.Body'?: string;
                'Template.Type'?: TemplateType;
                'Template.Thumbnail'?: string;
                'Template.PageWidth'?: number;
                'Template.PageHeight'?: number;
                'Template.IsFavorite'?: boolean;
                'Template.TemplateTracks'?: TemplateTrack[];
                TemplateBody: string;
                Tracks?: Track[];
                SelectedTracks?: number[];
                'AvailableProperties.TypeDefinitions'?: Record<string, Record<string, PropInfo>>;
            },
            params: RequestParams = {},
        ) =>
            this.request<number, void>({
                path: `/Templates/EditApi/${id}`,
                method: 'POST',
                body: data,
                type: ContentType.FormData,
                format: 'json',
                ...params,
            }),
    };
    transactions = {
        /**
         * No description
         *
         * @tags Transactions
         * @name CreateTransactionGroup
         * @request POST:/transactions/CreateTransactionGroup
         */
        createTransactionGroup: (data: TransactionGroupViewModel, params: RequestParams = {}) =>
            this.request<void, void>({
                path: `/transactions/CreateTransactionGroup`,
                method: 'POST',
                body: data,
                type: ContentType.Json,
                ...params,
            }),

        /**
         * No description
         *
         * @tags Transactions
         * @name EditTransactionGroup
         * @request POST:/transactions/EditTransactionGroup
         */
        editTransactionGroup: (data: TransactionGroupViewModel, params: RequestParams = {}) =>
            this.request<void, void>({
                path: `/transactions/EditTransactionGroup`,
                method: 'POST',
                body: data,
                type: ContentType.Json,
                ...params,
            }),

        /**
         * No description
         *
         * @tags Transactions
         * @name GetTransactionGroup
         * @request POST:/transactions/GetTransactionGroup/{id}
         */
        getTransactionGroup: (id: number, params: RequestParams = {}) =>
            this.request<TransactionGroupViewModel, void>({
                path: `/transactions/GetTransactionGroup/${id}`,
                method: 'POST',
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags Transactions
         * @name LastTransactionId
         * @request POST:/transactions/LastTransactionId
         */
        lastTransactionId: (query?: { transactionType?: TransactionType }, params: RequestParams = {}) =>
            this.request<number, void>({
                path: `/transactions/LastTransactionId`,
                method: 'POST',
                query: query,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags Transactions
         * @name ListTransactionGroups
         * @request POST:/transactions/listTransactionGroups
         */
        listTransactionGroups: (data: LTGViewModel, params: RequestParams = {}) =>
            this.request<TransactionGroup[], ProblemDetails | void>({
                path: `/transactions/listTransactionGroups`,
                method: 'POST',
                body: data,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),
    };
}
