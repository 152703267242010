import React, { FC } from 'react';
import ReactDatePicker from 'react-datepicker';
import styled from 'styled-components';
import { Types } from '../Table';

export const StyledInput = styled.input`
    width: 100%;
    box-sizing: border-box;
    border-radius: 10px;
    font-size: inherit;
    height: 2.3em;
    min-height: 2.3em;
    min-width: 2.3em;
    padding: 0 0.5em;
    margin: 0;
    font-weight: bold;
    outline: none;
    border: solid 1px #8eb0eb;
    background-color: transparent;
    transition: color 0.5s ease, background-color 0.5s ease;
    color: var(--text-color);
    background-image: none;
    box-shadow: none;
`;

export const DatePicker = styled(({ className, ...props }) => (
    <ReactDatePicker {...props} customInput={<StyledInput />} wrapperClassName={className} />
))`
    width: 100%;
`;

export interface InputProps<T> {
    type: Types;
    value: T;
    onChange: (value: T) => void;
}
export function Input<T>({ type, value, onChange }: InputProps<T>) {
    switch (type) {
        case Types.date:
            const date = typeof value === 'string' ? new Date(value) : new Date();
            if (isNaN(date.getTime())) return <code style={{ color: 'red' }}>{value}</code>;
            return (
                <ReactDatePicker
                    selected={date}
                    onChange={(d) => d instanceof Date && onChange(d.toISOString() as unknown as T)} // HACK c'mon typescript
                />
            );
        default:
            return (
                <input value={JSON.stringify(value)} onChange={(e) => onChange(JSON.parse(e.currentTarget.value))} />
            );
    }
}
