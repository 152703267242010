import React, { FC, ReactNode, useLayoutEffect, useRef, useState } from 'react';
import AnimateHeight from 'react-animate-height';
import styled, { css } from 'styled-components';
import { Spinner } from './Spinner';

const Summary = styled.summary`
    display: flex;
    align-items: center;
    gap: 0.4em;
    border-top: var(--border);
    padding: 0.5em 1em;
    font-size: 1.2em;
    font-weight: bold;
    text-transform: uppercase;
    cursor: default;
`;

const SectionContent = styled.section`
    margin-left: 2em;
`;

const Caret = styled.i<{ isOpen: boolean }>`
    transition: ease 0.4s transform;
    transform-origin: center;
    ${(props) =>
        props.isOpen
            ? css`
                  transform: rotate(90deg);
              `
            : ''}
`;

export interface AccordionSectionProps {
    tabIndex?: number;
    title: string;
    isOpen: boolean;
    onToggle: (isOpen: boolean) => void;
    description?: string;
}
export const AccordionSection: FC<AccordionSectionProps> = ({ isOpen: animationOpen, onToggle, title, children, description }) => {
    const wasEverOpen = useRef(animationOpen);
    wasEverOpen.current = wasEverOpen.current || animationOpen;
    const [detailsOpen, setDetailsOpen] = useState(animationOpen);

    const handleSummaryClick = (e: React.MouseEvent<HTMLElement>) => {
        e.preventDefault();
        const details = e.currentTarget.parentElement;
        if (details instanceof HTMLDetailsElement) {
            onToggle(!animationOpen);
        }
    };
    return (
        <details open={detailsOpen || animationOpen}>
            <Summary onClick={handleSummaryClick}>
                <Caret isOpen={animationOpen} className="fas fa-caret-right" />
                {title}
                {description ? <i className="fa fa-question-circle" title={description}></i> : null}
            </Summary>
            <AnimateHeight duration={400} easing="ease-out" height={animationOpen ? 'auto' : 0} onAnimationEnd={() => setDetailsOpen(animationOpen)}>
                <SectionContent>{wasEverOpen.current ? children : <Spinner />}</SectionContent>
            </AnimateHeight>
        </details>
    );
};

type SectionsMap = { [title: string]: { description?: string; children: ReactNode } };
export interface AccordionProps<T extends SectionsMap> {
    sections: T;
    defaultOpenSection?: keyof T;
}

export function Accordion<T extends SectionsMap>(props: AccordionProps<T>) {
    const [openSection, setOpenSection] = useState<keyof T | null>(props.defaultOpenSection || null);

    const handleSectionToggle = (sectionTitle: keyof T, isOpen: boolean) => {
        if (isOpen) setOpenSection(sectionTitle);
        else if (sectionTitle === openSection) setOpenSection(null);
    };

    return (
        <dl>
            {Object.entries(props.sections).map(([title, { children, description }]) => {
                return (
                    <AccordionSection
                        key={title}
                        title={title}
                        description={description}
                        isOpen={title === openSection}
                        onToggle={(isOpen) => handleSectionToggle(title, isOpen)}
                    >
                        {children}
                    </AccordionSection>
                );
            })}
        </dl>
    );
}
