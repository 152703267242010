import { HTMLProps, ReactNode, useState } from 'react';
import { Column, ColumnOf, display, types, Types } from '.';
import Select from '../Input/Select';

export interface TableRowProps<T> extends HTMLProps<HTMLTableRowElement> {
    record: T;
    columns: ColumnOf<T>[];
    onRecordChange?: (record: T) => void;
    onRecordClick?: (record: T, clickedColumn: ColumnOf<T>) => void;
    selected?: boolean;
    onSelectionChange?: (isSelected: boolean) => void;
    actions?: ReactNode;
    focus?: boolean;
}

export default function <T>({
    record,
    selected,
    onSelectionChange,
    onRecordClick,
    onRecordChange,
    columns,
    focus,
    actions,
    ...props
}: TableRowProps<T>) {
    const [isEditable, setIsEditable] = useState<{ focusedColumn: ColumnOf<T> }>();
    return (
        <tr {...props}>
            {onSelectionChange && (
                <td>
                    <input
                        type="checkbox"
                        checked={selected}
                        onChange={onSelectionChange ? (e) => onSelectionChange(e.currentTarget.checked) : undefined}
                    />
                </td>
            )}
            {columns.map((column, i) => {
                const autoFocus = (isEditable && isEditable.focusedColumn === column) || (focus && i === 0);
                return focus || isEditable ? (
                    <td key={'' + column.name}>
                        {column.type === Types.ref ? (
                            <Select {...{ autoFocus, record, column, onRecordChange }} />
                        ) : (
                            <input
                                autoFocus={autoFocus}
                                value={display(record, column)}
                                onChange={(e) =>
                                    onRecordChange?.({
                                        ...record,
                                        [column.name]: types[column.type](e.currentTarget.value),
                                    })
                                }
                            />
                        )}
                    </td>
                ) : (
                    <td
                        key={'' + column.name}
                        onClick={() => onRecordClick?.(record, column)}
                        onMouseDown={onRecordChange ? () => setIsEditable({ focusedColumn: column }) : undefined}
                    >
                        {display(record, column)}
                    </td>
                );
            })}
            {actions && (
                <td>
                    <div className="actions">{actions}</div>
                </td>
            )}
        </tr>
    );
}
