import { FC, HTMLProps, useState } from 'react';

export interface ImageUploadProps {
    name?: string;
    defaultValue?: string;
    folder?: string;
}
export const ImageUpload: FC<ImageUploadProps> = ({ name, folder, defaultValue }) => {
    const [fileName, setFileName] = useState<string>();
    const [progress, setProgress] = useState(NaN);

    const handleProgress = (e: ProgressEvent<XMLHttpRequestEventTarget>) => {
        // upload progress as percentage
        const percent_completed = (e.loaded / e.total) * 100;
        setProgress(percent_completed);
    };

    const handleFileChange: HTMLProps<HTMLInputElement>['onChange'] = (e) => {
        const fileInput = e.currentTarget;
        if (fileInput.files) {
            setProgress(0);
            const request = new XMLHttpRequest();
            request.open('POST', '/shared/uploadFile' + (folder ? `?folder=${folder}` : ''));

            // upload progress event
            request.upload.addEventListener('progress', handleProgress);

            // request finished event
            request.addEventListener('load', function () {
                if (request.status === 200) {
                    setFileName(request.responseText);
                    setProgress(NaN);
                } else alert('an error occurred');
            });
            const data = new FormData();
            data.append('file', fileInput.files[0]);
            request.send(data);
        }
    };

    const imgSrc = fileName || defaultValue;
    const isNotUploading = isNaN(progress);

    return (
        <div className={`image-upload ${isNotUploading && imgSrc ? 'has-img' : ''}`}>
            {imgSrc ? <img src={'/uploads/' + (folder ? folder + '/' : '') + imgSrc} /> : null}
            <input name={name} type="hidden" value={fileName || defaultValue || ''} required />
            <label>
                <i className="fa fa-file-upload" />
                <input type="file" accept="image/*" value={''} onChange={handleFileChange} hidden />
                {progress > 0 ? <progress max="100" value={progress} /> : null}
            </label>
        </div>
    );
};
