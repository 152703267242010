import { FC, FormEvent, useRef, useState } from 'react';
import styled from 'styled-components';
import { CustomFieldType, CustomListItem, CustomListItemUse, Api } from '../api-client';
import { BindingProps, useErrors } from '../lib/Form';
import { Spinner } from '../lib/Spinner';
import { RecursivePartial } from '../lib/util';
interface CustomListProps
    extends BindingProps<
        RecursivePartial<CustomListItem[]>,
        RecursivePartial<CustomListItem[]>,
        Record<number, CustomListItem>
    > {
    fieldType?: CustomFieldType;
    listName: string;
    tooltip?: string;
}

export const CustomList: FC<CustomListProps> = ({
    listName,
    tooltip,
    value: model,
    onChange: onModelChange,
    onValidityChange,
    fieldType,
    modelValidity,
}) => {
    const getItemId = (item: RecursivePartial<CustomListItem>): number => {
        if (item.id !== undefined) {
            return item.id;
        } else {
            throw new Error('item without id');
        }
    };

    const [focus, setFocus] = useState(-1);

    const { track } = useErrors({ onValidityChange, modelValidity });

    const newItemsId = useRef(0);

    const handleMove = (i: number, movement: 1 | -1) => {
        setFocus(focus + movement);
        // swap item with either the previous or next one.
        const newPosition = i + movement;
        if (newPosition >= 0 && newPosition < model.length) {
            const newModel = [...model];
            [newModel[i], newModel[newPosition]] = [newModel[newPosition], newModel[i]];
            onModelChange(newModel);
        }
    };

    const handleItemChange = (newItem: RecursivePartial<CustomListItem>, index: number) => {
        onModelChange(Object.assign([], model, { [index]: newItem }));
    };

    const handleItemDelete = (item: RecursivePartial<CustomListItem>) => {
        onModelChange(model.filter((x) => x != item));
        // setItemValidity(getItemId(item), false);
    };

    const handleAdd = () => {
        setFocus(model.length);
        onModelChange([...model, { id: newItemsId.current, name: '' }]);
        // setItemValidity(newItemsId.current, true);
        newItemsId.current--;
    };

    return (
        <div>
            <h4>
                {listName} {tooltip ? <i className="fa fa-question-circle" title={tooltip}></i> : null}
            </h4>
            <div className="form-group">
                <div className="field-w name-list">
                    <div className="dyn-list">
                        {model.map((item, i) => {
                            return (
                                <CustomListItemView
                                    key={getItemId(item)}
                                    autoFocus={focus === i}
                                    value={item}
                                    onChange={(newModel) => handleItemChange(newModel, i)}
                                    onDelete={() => handleItemDelete(item)}
                                    onMove={(movement) => handleMove(i, movement)}
                                    fieldType={fieldType}
                                    {...track(getItemId(item))}
                                />
                            );
                        })}
                    </div>
                    <button type="button" className="btn btn-run icon-new add" onClick={handleAdd}>
                        Add
                    </button>
                    <div className="field-validation-error err-msg"></div>
                </div>
            </div>
        </div>
    );
};

const Warning = styled.div`
    position: absolute;
    opacity: 0;
    transition: 0.4s ease opacity;
    width: 20em;
    padding: 0.5em;
    background-color: #ffb9b9;
    border-radius: 10px;
    top: 2em;
    right: 0;
`;

const WarningContainer = styled.div`
    position: relative;
    color: red;

    &:hover ${Warning} {
        opacity: 1;
    }
`;

interface CustomListItemProps extends BindingProps<RecursivePartial<CustomListItem>> {
    fieldType: CustomFieldType | undefined;

    autoFocus: boolean;
    onDelete: () => void;
    onMove: (position: 1 | -1) => void;
}
export const CustomListItemView: FC<CustomListItemProps> = ({
    fieldType,
    value: model,
    onChange: onModelChange,
    onDelete,
    onMove,
    autoFocus,
    // onValidityChange,
    modelValidity: imToldImInvalid,
}) => {
    // const [iThinkImInvalid, setIsInvalid] = useState(false);

    const [deleteStatus, setDeleteStatus] = useState<'loading' | CustomListItemUse[]>();

    const [showUses, setShowUses] = useState(false);

    const isInvalid = /* iThinkImInvalid  ||*/ imToldImInvalid;

    // const validate = (model: RecursivePartial<CustomListItem>) => !model.name?.trim();

    const handleChange = (e: FormEvent<HTMLInputElement>) => {
        const newModel = { ...model, name: e.currentTarget.value };
        onModelChange(newModel);
        // const isInvalid = validate(newModel);
        // setIsInvalid(isInvalid);
        // onValidityChange && onValidityChange(isInvalid);
    };

    const handleDelete = async () => {
        if (fieldType && model.id) {
            setDeleteStatus('loading');
            const { data: uses } = await Api.settings.customListItemUses({ fieldType, customLitItemId: model.id });
            if (uses.length > 0) {
                setDeleteStatus(uses);
                return;
            }
        }
        onDelete();
    };

    return (
        <div className="item">
            <div className="name-w">
                <input
                    style={isInvalid ? { borderColor: 'red' } : undefined}
                    autoFocus={autoFocus}
                    type="text"
                    className="name"
                    value={model.name || ''}
                    onChange={handleChange}
                />
                {isInvalid ? <div className="err-msg">required.</div> : null}
            </div>
            <div className="ctrls">
                <i className="fa fa-arrow-up" onClick={() => onMove(-1)}></i>
                <i className="fa fa-arrow-down" onClick={() => onMove(1)}></i>
                {deleteStatus === 'loading' ? (
                    <Spinner />
                ) : Array.isArray(deleteStatus) && deleteStatus.length > 0 ? (
                    <WarningContainer>
                        <i className="fa fa-exclamation-triangle" />
                        <Warning>
                            Cannot delete: This is used{' '}
                            {deleteStatus.length < 2 ? 'once' : deleteStatus.length + ' times'}.
                            <br />
                            <details open={showUses} onToggle={() => setShowUses((x) => !x)}>
                                <summary>show uses</summary>
                                {showUses ? (
                                    <ol>
                                        {deleteStatus.map((x) => (
                                            <li key={x.user.id.toString() + x.field.id}>
                                                User: {x.user.name};<br />
                                                Field: {x.tab.name} &gt; {x.section.name} &gt; {x.field.name}.
                                            </li>
                                        ))}
                                    </ol>
                                ) : undefined}
                            </details>
                        </Warning>
                    </WarningContainer>
                ) : (
                    <i className="fa fa-trash" onClick={handleDelete}></i>
                )}
            </div>
        </div>
    );
};
