import { HTMLProps } from 'react';
import { StyledComponentPropsWithRef } from 'styled-components';
import { Column, SortableTH, SortDirection } from '.';

export interface Props extends StyledComponentPropsWithRef<"th"> {
    column: Column;
    sort?: SortDirection;
    onSort?: () => void;
    onFilter?: () => void;
}

export default function (props: Props) {
    return (
        <SortableTH key={JSON.stringify(props.column.name) /* HACK */} {...props}>
            <span>{props.column.title}</span>
            {props.onFilter && <i className="fa fa-filter" onClick={props.onFilter} />}
            {props.onSort && <i className={`fa fa-sort${props.sort ? '-' + props.sort : ''}`} onClick={props.onSort} />}
        </SortableTH>
    );
}
