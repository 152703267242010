import { IDName } from '../../api-client';
import { Column, ColumnOf, types } from '../Table';

export interface Props<T> {
    autoFocus: boolean | undefined;
    record: T;
    column: ColumnOf<T>;
    onRecordChange: ((record: T) => void) | undefined;
}
export default function <T>({ autoFocus, column, onRecordChange, record }: Props<T>) {
    const { id, name } = (record[column.name] as unknown as IDName) || { id: '', name: '' }; // HACK
    return (
        <select
            autoFocus={autoFocus}
            value={id}
            onChange={(e) =>
                onRecordChange?.({
                    ...record,
                    [column.name]: types[column.type](e.currentTarget.value),
                })
            }
        >
            <option value="" />
            {Object.entries(column.available || { [id]: name }).map(([key, value]) => (
                <option key={key} value={key}>
                    {'' + value}
                </option>
            ))}
        </select>
    );
}
