// run dotnet build to generate api-client files
export * from './__generated__';
import { DependencyList, useEffect } from 'react';
import { Api as _Api, RequestParams } from './__generated__';

export const Api = new _Api({
    customFetch: (input, init) =>
        fetch(input, init).then((resp) => {
            if (resp.status === 401) {
                location.href = `/login?returnUrl=${location.pathname}?${location.search}`;
            }
            return resp;
        }),
});

export function useApi(callback: (api: _Api<unknown>, params: RequestParams) => Promise<void>, deps: DependencyList) {
    const cancelToken = Symbol();
    useEffect(() => {
        callback(Api, { cancelToken });
        return () => Api.abortRequest(cancelToken);
    }, deps);
    return { fetchData: () => callback(Api, { cancelToken }) };
}
