import { FormEvent, HTMLProps, Key, ReactElement, ReactNode, useState } from 'react';
import styled from 'styled-components';
import { IDName } from '../../api-client';
import TH from './TH';
import TableRow from './TableRow';

interface Or {
    or: Type;
}

interface Primitive<T> {
    type: T;
}

interface Number extends Primitive<'number'> {
    size: 16 | 32 | 64;
    format: '';
}

type Type = Or | Primitive<'string'> | Primitive<''> | Primitive<'string'> | Primitive<'string'> | Primitive<'string'>;

/** @deprecated use `keyof types` */
export enum Types {
    string = 'string',
    number = 'number',
    decimal = 'decimal',
    date = 'date',
    ref = 'idName',
    boolean = 'boolean',
}

export const types = {
    [Types.date]: (v: string) => new Date(v),
    [Types.number]: (v: string) => +v,
    [Types.decimal]: (v: string) => +v,
    [Types.string]: (v: string) => v,
    [Types.ref]: (v: string) => ({ id: +v }),
};

// class Converter<T, Params> {
//     constructor(public fromString: (s: string) => T, public toString: (t: T, params: Params) => string) {}
// }

// export const xtypes = {
//     [Types.date]: new Converter(
//         (s) => new Date(s),
//         (v) => v.toISOString(),
//     ),
//     [Types.number]: new Converter(
//         (s) => +s,
//         (v) => v.toString(),
//     ),
//     [Types.string]: new Converter(
//         (s) => s,
//         (v) => v,
//     ),
//     [Types.ref]: new Converter(
//         (s) => ({ id: +s }),
//         (v, mapping: { [id: number]: string }) => mapping[v.id],
//     ),
// };

export interface Column<
    Prop extends keyof any = keyof any,
    MyType extends keyof typeof types = keyof typeof types,
    Type = unknown,
> {
    /** display name */
    title: string;
    /** key in JSON */
    name: Prop;
    type: MyType;
    available?: any; // HACK
}

export type SortDirection = 'up' | 'down';

export const SortableTH = styled.th`
    cursor: pointer;

    & .fa-sort {
        opacity: 0;
        transition: opacity 0.4s ease;
    }

    &:hover .fa-sort {
        opacity: 1;
    }
`;

//HACK
function isKeyof<T extends object>(key: any, object: T): key is keyof T {
    return key in object;
}

export function display(record: any, column: Column): string {
    const value = record[column.name];
    if (value === null || value === undefined || value === '') return '';

    switch (column.type) {
        case Types.string:
            return value;
        case Types.number:
        case Types.decimal:
            return value.toString();
        case Types.date:
            return new Date(value).toLocaleString();
        case Types.ref:
            if (typeof value === 'object') {
                //HACK
                if (value.name != undefined) return value.name;
                else if (column.available && isKeyof(value, column.available)) {
                    return column.available[value];
                }
            }
            return '';
    }
}

export type ColumnOf<T> = Column<keyof T, keyof typeof types, T[keyof T]>;

export interface TableProps<T> {
    records: T[];
    columns: ColumnOf<T>[];
    /** takes a record, returns a list of actions for it */
    actionsGenerator?: (record: T, index: number) => ReactElement;
    getPrimaryKey: (record: T, index: number) => string;
    onRecordChange?: (record: T, index: number) => void;
    selectedRecords?: Record<string, T>;
    onRecordClick?: (record: T, index: number) => void;
    onRecordSelectionChange?: (selectedRecords: Record<string, T>) => void;
    focusedRow?: number;
}

export function Table<T = object>({
    records,
    columns,
    actionsGenerator,
    getPrimaryKey,
    onRecordChange,
    selectedRecords,
    onRecordSelectionChange,
    focusedRow,
    onRecordClick,
}: TableProps<T>) {
    type Col = ColumnOf<T>;
    const [sortColumn, setSortColumn] = useState<Col>();
    const [sortDirection, setSortDirection] = useState<SortDirection>('up');

    const handleColumnSort = (column: Col) => {
        if (sortColumn?.name === column.name) setSortDirection((x) => (x === 'up' ? 'down' : 'up'));
        else {
            setSortColumn(column);
            setSortDirection('up');
        }
    };

    // TODO if outside sort function
    const sort = (a: any, b: any) => {
        if (sortColumn) {
            // flip comparing items if sort direction is 'down'
            const [x, y] =
                sortDirection == 'up'
                    ? [a[sortColumn.name], b[sortColumn.name]]
                    : [b[sortColumn.name], a[sortColumn.name]];
            if (x === null || x === undefined) return -1;
            switch (sortColumn.type) {
                case Types.string:
                    return x.localeCompare(y);
                case Types.number:
                    return x - y;
                case Types.date:
                    return new Date(x.toString()).getTime() - new Date(y.toString()).getTime();
            }
        }
        return 0;
    };

    const handleRecordCheckboxChange = (record: T, index: number, selected: boolean) => {
        if (onRecordSelectionChange) {
            const key = getPrimaryKey(record, index);
            if (selected != !!selectedRecords?.[key]) {
                if (selected) {
                    onRecordSelectionChange({ ...selectedRecords, [key]: record });
                } else {
                    const { [key]: _deleted, ...rest } = selectedRecords || {};
                    onRecordSelectionChange(rest);
                }
            }
        }
    };

    const handleSelectAll = onRecordSelectionChange
        ? (e: FormEvent<HTMLInputElement>) =>
              onRecordSelectionChange?.(
                  e.currentTarget.checked ? Object.fromEntries(records.map((x, i) => [getPrimaryKey(x, i), x])) : {},
              )
        : undefined;

    return (
        <table className="table">
            <thead>
                <tr>
                    {handleSelectAll && (
                        <th>
                            <input type="checkbox" onChange={handleSelectAll} />
                        </th>
                    )}
                    {columns.map(
                        (
                            column, // HACK keyof T is always string
                        ) => (
                            <TH
                                key={JSON.stringify(column.name)}
                                column={column}
                                onSort={() => handleColumnSort(column)}
                            />
                        ),
                    )}
                    {actionsGenerator && <th />}
                </tr>
            </thead>
            <tbody>
                {records.length > 0 ? (
                    (sortColumn ? records.sort(sort) : records).map((record, i) => {
                        const primaryKey = getPrimaryKey(record, i);
                        return (
                            <TableRow
                                key={primaryKey}
                                columns={columns}
                                record={record}
                                onRecordChange={onRecordChange ? (record) => onRecordChange(record, i) : undefined}
                                selected={!!selectedRecords?.[primaryKey]}
                                onSelectionChange={
                                    onRecordSelectionChange
                                        ? (isSelected) => handleRecordCheckboxChange(record, i, isSelected)
                                        : undefined
                                }
                                onRecordClick={(record) => onRecordClick?.(record, i)}
                                focus={focusedRow === i}
                                actions={actionsGenerator?.(record, i)}
                            />
                        );
                    })
                ) : (
                    <tr>
                        <td colSpan={columns.length}>No Data Available</td>
                    </tr>
                )}
            </tbody>
        </table>
    );
}
