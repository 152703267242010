import { Editor } from '@tinymce/tinymce-react';
import React, { FC, FormEvent, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { Editor as XEditor } from 'tinymce';
import { singular } from 'pluralize';
import { PropertyTree, PropertyTreeProps, TypeDefinition } from '../lib/templates/TemplateIndex/PropertyTree';
import { ImageUpload } from '../lib/image-upload';
import { paperSizes, RecursivePartial } from '../lib/util';
import { Body, Footer, Header, Page, Title } from '../lib/Page';
import { TemplateDetailsDTO, TemplateType, useApi } from '../api-client';
import { FieldError, FormGroup, FormStatus, ObjectValidity, useErrors } from '../lib/Form';
import { ActionsMenu } from '../lib/ActionsMenu/ActionsMenu';
import { SaveButton } from '../lib/Form/SaveButton';
import { Link } from 'react-router-dom';
import { Button } from '../lib/Button';
import { Spinner } from '../lib/Spinner';
import styled from 'styled-components';
import Select from 'react-select';

/** factor of conversion from millimeters to points */
const mm2pt = 2.83465;
/** factor of conversion from points to millimeters */
const pt2mm = 0.352778;

function handlePageSize(event: FormEvent<HTMLInputElement>, action: (val: number) => void) {
    // HACK: keep caret position
    const element = event.currentTarget;
    const caret = element.selectionStart;
    window.requestAnimationFrame(() => {
        element.selectionStart = caret;
        element.selectionEnd = caret;
    });

    const value = +element.value;
    if (!isNaN(value)) {
        action(value * mm2pt);
    }
}

interface Padding {
    top?: string;
    left?: string;
    bottom?: string;
    right?: string;
}

export interface TemplateEditProps {
    id: number;
    cloneFrom: number;
}
export const TemplateEdit: FC<TemplateEditProps> = ({ id: _id, cloneFrom }) => {
    const [model, setModel] = useState<RecursivePartial<TemplateDetailsDTO, 'availableProperties'>>({});
    const { availableProperties: typeDefs, template, selectedTracks, tracks, templateBody } = model;

    const id = template?.id || _id;

    const { setModelValidity, track } = useErrors<TemplateDetailsDTO>({});
    const { track: trackTemplate } = useErrors(track('template'));

    const [formStatus, setFormStatus] = useState<FormStatus>('loading');

    useApi(async (api, params) => {
        var { data: model } = await api.templates.getTemplate(_id, { cloneFrom }, params);
        setModel(model);
        setFormStatus('no-change');
    }, []);

    const editorRef = useRef<XEditor>();

    /** loops */
    const [openLoops, setOpenLoops] = useState<Set<string>>();

    const [searchTerm, setSearchTerm] = useState<string>();

    const pageWidth = template?.pageWidth || 0;
    const pageHeight = template?.pageHeight || 0;

    const setPageSize = ([pageWidth, pageHeight]: [number, number]) => {
        setModel({ ...model, template: { ...template, pageWidth, pageHeight } });
    };

    const [padding, setPadding] = useState<Padding>({});

    const contentStyle = () => `
        :root {
            width: ${pageWidth}pt;
            height: ${pageHeight}pt;
        }`;

    function setStyles(editor: XEditor) {
        if (editor.iframeElement && editor.iframeElement.contentDocument) {
            const doc = editor.iframeElement.contentDocument;
            const styleElems = doc.getElementsByTagName('style');
            if (styleElems.length > 0) {
                styleElems[0].innerHTML = contentStyle();
            }
            const templateRoot = doc.body.firstElementChild;
            // HACK
            const iWindow = editor.iframeElement.contentWindow as unknown as { HTMLElement: typeof HTMLElement };
            const iHTMLElement = iWindow.HTMLElement;

            if (templateRoot instanceof iHTMLElement) {
                if (Object.keys(padding).length === 0) {
                    const style = getComputedStyle(templateRoot);
                    setPadding({
                        top: style.paddingTop,
                        right: style.paddingRight,
                        bottom: style.paddingBottom,
                        left: style.paddingLeft,
                    });
                } else {
                    if (padding.top) templateRoot.style.paddingTop = padding.top + (isNaN(+padding.top) ? '' : 'px');
                    if (padding.left)
                        templateRoot.style.paddingLeft = padding.left + (isNaN(+padding.left) ? '' : 'px');
                    if (padding.bottom)
                        templateRoot.style.paddingBottom = padding.bottom + (isNaN(+padding.bottom) ? '' : 'px');
                    if (padding.right)
                        templateRoot.style.paddingRight = padding.right + (isNaN(+padding.right) ? '' : 'px');
                    templateRoot.setAttribute('data-mce-style', templateRoot.getAttribute('style') || '');
                }
            }
        }
    }

    // HACK updates the editor style
    useEffect(() => {
        if (editorRef.current) setStyles(editorRef.current);
    }, [pageWidth, pageHeight, padding]);

    const handlePropertyClick: PropertyTreeProps['onPropertyClick'] = ({ path, parentTitle, title, isLoop }) => {
        const ed = editorRef.current;
        if (ed) {
            ed.focus();
            const selectionStart = ed.selection.getEnd();
            const elementInPosition = selectionStart.nodeName === 'BR' ? selectionStart.parentElement : selectionStart;
            const range = ed.selection.getRng();
            if (elementInPosition && range) {
                if (isLoop) {
                    const node = ed.selection.getNode();
                    if (node.nodeName === 'TD') {
                        const tr = node.parentElement as HTMLTableRowElement;
                        tr.dataset.forEach = path;
                        tr.title = title;
                        if (parentTitle) {
                            tr.dataset.parentTitle = parentTitle;
                        }
                    } else {
                        const contents = range.extractContents();
                        const elem = ed.dom.add(
                            range.commonAncestorContainer,
                            'div',
                            {
                                'data-for-each': path,
                                'data-parent-title': parentTitle || '',
                                title: singular(title),
                            },
                            '',
                        );

                        elem.appendChild(contents);

                        // after
                        ed.dom.add(elementInPosition, 'span', undefined, '&nbsp');

                        const last = elem.lastChild || ed.dom.add(elem, 'span', undefined, '&nbsp');
                        ed.selection.select(last);
                    }
                } else {
                    if (elementInPosition.firstElementChild?.hasAttribute('data-mce-bogus'))
                        ed.dom.remove(elementInPosition.firstElementChild);
                    const badge = ed.dom.add(
                        elementInPosition,
                        'span',
                        { 'data-var': path, contenteditable: false },
                        (parentTitle ? `<span class="parent">${parentTitle}</span>` : '') +
                            `<span class="property ${parentTitle ? '' : 'lone'}">${title}</span>`,
                    );
                    ed.selection.select(badge);
                }
            }
        }
    };

    const handleSelectionChange = () => {
        const ed = editorRef.current;
        if (ed) {
            const stackPath: string[] = [];
            for (let elem = ed.selection.getStart() as HTMLElement; elem.parentElement; elem = elem.parentElement) {
                if (elem.dataset.forEach) {
                    stackPath.push(elem.dataset.forEach);
                }
            }
            setOpenLoops(new Set(stackPath));
        }
    };

    const encapsulate = () => {
        const ed = editorRef.current;
        if (ed) {
            const range = ed.selection.getRng();
            if (range) {
                const div = ed.dom.create('div', { style: 'display: inline-block' });
                ed.dom.insertAfter(div, range.endContainer);
                div.appendChild(range.extractContents());
            }
        }
    };

    const validate = (): boolean => {
        let errors: ObjectValidity<TemplateDetailsDTO> = {};

        if (model.selectedTracks?.length === 0) errors.selectedTracks = 'Tracks are required';
        if (pageHeight <= 0 && pageWidth <= 0)
            errors.template = { pageHeight: 'Must be greater than zero', pageWidth: 'Must be greater than zero' };

        setModelValidity(errors);
        return Object.entries(errors).length === 0;
    };

    const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if (validate()) {
            setFormStatus('saving');
            const formData = new FormData(event.currentTarget);
            // TODO api client
            const response = await fetch('/templates/editApi', {
                method: 'POST',
                body: formData,
            });
            if (response.ok) {
                // for new templates
                if (!template?.id) {
                    const newID = await response.text();
                    setModel({ ...model, template: { ...template, id: +newID } });
                }
                setFormStatus('saved');
            } else {
                setFormStatus('error');
                if (response.status == 422) {
                    const result: Record<string, string[]> = await response.json();
                    setModelValidity(
                        Object.fromEntries(
                            Object.entries(result).map(([key, value]) => [
                                // Pascal-case to camel-case
                                key[0].toLowerCase() + key.substr(1, key.length),
                                value.join(','),
                            ]),
                        ),
                    );
                }
            }
        } else setFormStatus('invalid');
    };

    const handleDelete = async (e: React.MouseEvent) => {
        e.preventDefault();
        try {
            //@ts-ignore //HACK
            await modals.confirm(`Are you sure you want to delete?`, { delete: true });
            location.href = '/templates/delete/' + id;
        } catch {}
    };

    return (
        <Page as="form" onSubmit={handleSubmit} onChange={() => setFormStatus('changed')}>
            <Header>
                <Title>{id ? <>Edit Template - {template?.name || <Spinner />}</> : 'Create Template'}</Title>
                <ActionsMenu>
                    <a onClick={handleDelete}>
                        <div className="fa fa-trash"></div>
                        <span>Delete</span>
                    </a>
                    <a href={'/templates/edit?cloneFrom=' + id}>
                        <i className="fa fa-clone"></i>
                        <span>Duplicate</span>
                    </a>
                </ActionsMenu>
            </Header>
            <Body>
                <input defaultValue={template?.id} name="template.ID" type="hidden" />
                <input defaultValue={template?.schoolID || ''} name="template.SchoolID" type="hidden" />
                <div className="form-section" style={{ marginBottom: 0 }}>
                    <div className="form-group">
                        <label className="control-label">Name</label>
                        <div className="field-w">
                            <input
                                type="text"
                                name="template.Name"
                                value={template?.name}
                                onChange={(e) =>
                                    setModel({
                                        ...model,
                                        template: { ...template, name: e.currentTarget.value || undefined },
                                    })
                                }
                                required
                            />
                        </div>
                    </div>
                    <div className="form-group">
                        <label className="control-label">Type</label>
                        <div className="field-w">
                            <select
                                name="template.Type"
                                value={template?.type || ''}
                                onChange={(e) =>
                                    setModel({
                                        ...model,
                                        template: { ...template, type: +e.currentTarget.value || undefined },
                                    })
                                }
                            >
                                <option value="">Select...</option>
                                <option value={TemplateType.MultiYear}>Multi Year</option>
                                <option value={TemplateType.SingleYear}>Single Year</option>
                            </select>
                        </div>
                    </div>
                    <FormGroup label="Tracks" {...track('selectedTracks')}>
                        <Select
                            isMulti
                            onChange={(v) => {
                                setFormStatus('changed'),
                                    setModel({ ...model, selectedTracks: v.map((x) => x.value || 0) });
                            }}
                            value={tracks
                                ?.filter((x) => x.id && selectedTracks?.includes(x.id))
                                .map(({ id, name }) => ({ label: name, value: id }))}
                            name="SelectedTracks"
                            options={tracks?.map(({ id, name }) => ({ label: name, value: id }))}
                        />
                    </FormGroup>

                    <div className="form-group">
                        <label className="control-label">Thumbnail</label>
                        <div className="field-w">
                            <ImageUpload
                                name="template.Thumbnail"
                                defaultValue={template?.thumbnail || 'default.jpg'}
                                folder="template-thumbnails"
                            />
                        </div>
                    </div>

                    <div className="form-group">
                        <label className="control-label">Page Size</label>
                        <div className="field-w">
                            <select
                                value={`${pageWidth}x${pageHeight}`}
                                onChange={(e) => {
                                    const value = e.currentTarget.value;
                                    if (value) {
                                        const [width, height] = value.split('x');
                                        setPageSize([+width, +height]);
                                    } else {
                                        setPageSize([0, 0]);
                                    }
                                }}
                            >
                                <option value="">Custom</option>
                                {Object.entries(paperSizes).map(([name, [width, height]]) => (
                                    <option key={`${width}x${height}`} value={`${width}x${height}`}>
                                        {name}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </div>
                    <div className="two-form-groups">
                        <FormGroup label="" {...trackTemplate('pageWidth')}>
                            <label>Width</label>
                            <br />
                            {/* submit value in points */}
                            <input type="hidden" name="template.PageWidth" defaultValue={pageWidth} />
                            {/* display value in mm */}
                            <input
                                type="text"
                                className="short-input"
                                value={Math.floor(pageWidth * pt2mm)}
                                onChange={(event) => handlePageSize(event, (value) => setPageSize([value, pageHeight]))}
                                required
                            />
                            <span> mm</span>
                        </FormGroup>
                        <FormGroup {...trackTemplate('pageHeight')}>
                            <label>Height</label>
                            <br />
                            {/* submit value in points */}
                            <input type="hidden" name="template.PageHeight" defaultValue={pageHeight} />
                            {/* display value in mm */}
                            <input
                                type="text"
                                className="short-input"
                                value={Math.floor(pageHeight * pt2mm)}
                                onChange={(event) => handlePageSize(event, (value) => setPageSize([pageWidth, value]))}
                                required
                            />
                            <span> mm</span>
                        </FormGroup>
                    </div>

                    {/* margins */}
                    <div className="two-form-groups">
                        <div className="form-group">
                            <label className="control-label">Margins</label>
                            <div className="field-w">
                                <label>Top</label>
                                <br />
                                <input
                                    type="text"
                                    className="short-input"
                                    value={padding.top || ''}
                                    onChange={(event) => setPadding({ ...padding, top: event.currentTarget.value })}
                                />
                            </div>
                        </div>
                        <div className="form-group">
                            <div className="field-w">
                                <label>Left</label>
                                <br />
                                <input
                                    type="text"
                                    className="short-input"
                                    value={padding.left || ''}
                                    onChange={(event) => setPadding({ ...padding, left: event.currentTarget.value })}
                                />
                            </div>
                        </div>
                        <div className="form-group">
                            <div className="field-w">
                                <label>Bottom</label>
                                <br />
                                <input
                                    type="text"
                                    className="short-input"
                                    value={padding.bottom || ''}
                                    onChange={(event) => setPadding({ ...padding, bottom: event.currentTarget.value })}
                                />
                            </div>
                        </div>
                        <div className="form-group">
                            <div className="field-w">
                                <label>Right</label>
                                <br />
                                <input
                                    type="text"
                                    className="short-input"
                                    value={padding.right || ''}
                                    onChange={(event) => setPadding({ ...padding, right: event.currentTarget.value })}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                {/** intelli-sense */}
                <fieldset className="intelli-sense">
                    <legend>
                        <span>Available Fields</span>
                        &nbsp;
                        <span className="input">
                            <input
                                placeholder="Search..."
                                type="search"
                                value={searchTerm}
                                onChange={(e) => setSearchTerm(e.currentTarget.value)}
                            />
                        </span>
                    </legend>
                    {typeDefs && (
                        <PropertyTree
                            {...{
                                openLoops: openLoops,
                                typeDefs: typeDefs,
                                currentType: typeDefs.typeDefinitions.TranscriptModel,
                                searchTerm: searchTerm,
                            }}
                            onPropertyClick={handlePropertyClick}
                        />
                    )}
                </fieldset>
                <br />
                {/* sorry:) */}
                {((x) => x && <FieldError>{x}</FieldError>)(track('templateBody').modelValidity)}
                <Editor
                    apiKey="8ibmo2krith71psvwi7whvktgvtoo2fxlmi8f5j00lakuthz"
                    textareaName="TemplateBody"
                    initialValue={templateBody}
                    onSelectionChange={handleSelectionChange}
                    onChange={() => setFormStatus('changed')}
                    onInit={(_evt, editor) => {
                        editorRef.current = editor;
                    }}
                    onEditorChange={(_evt, editor) => {
                        setStyles(editor);
                    }}
                    init={{
                        verify_html: false,
                        plugins: 'code table image',
                        images_upload_url: '/shared/tinymceUpload',
                        height: 600,
                        toolbar:
                            'undo redo | styleselect | bold italic | alignleft aligncenter alignright alignjustify | outdent indent | encapsulate',
                        menu: {
                            file: { title: 'File', items: 'newdocument restoredraft | preview | print ' },
                            edit: {
                                title: 'Edit',
                                items: 'undo redo | cut copy paste | selectall | searchreplace | forecolor backcolor',
                            },
                            view: {
                                title: 'View',
                                items: 'code | visualaid visualchars visualblocks | spellchecker | preview fullscreen',
                            },
                            insert: {
                                title: 'Insert',
                                items: 'image link media template codesample inserttable | charmap emoticons hr | pagebreak nonbreaking anchor toc | insertdatetime',
                            },
                            format: {
                                title: 'Format',
                                items: 'bold italic underline strikethrough superscript subscript codeformat | formats blockformats fontformats fontsizes align lineheight | forecolor backcolor | removeformat',
                            },
                            tools: { title: 'Tools', items: 'spellchecker spellcheckerlanguage | code wordcount' },
                            table: { title: 'Table', items: 'inserttable | cell row column | tableprops deletetable' },
                        },
                        content_css: '/res/css/site.css?v=1',
                        content_style: contentStyle(),
                        setup: (_editor) => {
                            _editor.ui.registry.addButton('encapsulate', {
                                text: 'Enc.',
                                onAction: encapsulate,
                            });
                        },
                    }}
                />
            </Body>
            <Footer>
                <SaveButton formStatus={formStatus} />
                <Button large as={Link} to="/settings/templates">
                    Cancel
                </Button>
            </Footer>
        </Page>
    );
};
export default TemplateEdit;
