import { FC } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { School, Year } from '../api-client';
import { FormGroup, NullableBindingProps } from '../lib/Form';
import { DatePicker } from '../lib/Input';
interface SchoolOptionsProps extends NullableBindingProps<School> {
    years: Year[];
}
export const SchoolOptions: FC<SchoolOptionsProps> = ({ value: model, onChange: onModelChange, years }) => {
    return (
        <div className="form-section">
            <h4>
                Current Year{' '}
                <i
                    className="fa fa-question-circle"
                    title="At the beginning of every school year, you must tell the system which new year is in effect.  This will affect details you see around the attendance system."
                ></i>
            </h4>
            <div className="form-group">
                <label className="control-label">Current Year</label>
                <div className="field-w">
                    <select
                        value={model?.currentYearID || ''}
                        onChange={
                            model
                                ? (e) => onModelChange({ ...model, currentYearID: +e.currentTarget.value || undefined })
                                : undefined
                        }
                    >
                        {years
                            .sort((a, b) => new Date(b.startDate).getTime() - new Date(a.endDate).getTime())
                            .map(({ id, name }) => (
                                <option key={id} value={id}>
                                    {name}
                                </option>
                            ))}
                    </select>
                </div>
            </div>
            <FormGroup label="Fiscal Year">
                <DatePicker
                    dateFormat={'MMM dd'}
                    dateFormatCalendar={'MMM dd'}
                    selected={model ? new Date(model.fiscalYear) : undefined}
                    onChange={(date: Date | unknown) =>
                        model &&
                        date instanceof Date &&
                        onModelChange({ ...model, fiscalYear: date.toLocaleDateString('sv') })
                    }
                />
            </FormGroup>
            <h4>Classes</h4>
            <div className="form-group">
                <label className="control-label">
                    Attendance % Pass <br />
                    <small>i.e. Honor Roll</small>
                </label>
                <div className="field-w">
                    <input
                        type="text"
                        className="number-input"
                        value={model?.attPercentPass || ''}
                        onChange={
                            model
                                ? (e) => onModelChange({ ...model, attPercentPass: +e.currentTarget.value })
                                : undefined
                        }
                    />
                </div>
            </div>
            <div className="form-group">
                <label className="control-label">Default Class Length</label>
                <div className="field-w">
                    <input
                        type="text"
                        className="number-input"
                        value={model?.defaultClassLength || ''}
                        onChange={(e) =>
                            model
                                ? onModelChange({
                                      ...model,
                                      defaultClassLength: +e.currentTarget.value,
                                  })
                                : undefined
                        }
                    />
                </div>
            </div>
        </div>
    );
};
