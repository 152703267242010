import { TemplateSelect } from './lib/template-select';
import ReactDOM from 'react-dom';
import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { App } from './App';

export { default as ReactDOM } from 'react-dom';
export { default as React } from 'react';
export { Table } from './lib/Table';

export function openSelectTemplate(studentIds: number[]): void {
    const container = document.createElement('div');
    container.style.display = 'block';
    container.className = 'modal';
    const handleClose = () => {
        ReactDOM.unmountComponentAtNode(container);
        container.remove();
        location.reload();
    };
    document.body.prepend(container);
    ReactDOM.render(<TemplateSelect studentIds={studentIds} onClose={handleClose} />, container);
}

export const history = createBrowserHistory();

export function reactTab(event: Event) {
    event.preventDefault();
    const target = event.currentTarget || event.target;
    if (target instanceof HTMLAnchorElement) {
        $(target).toggleClass('active', true).trigger('blur').siblings().toggleClass('active', false);
        const url = new URL(target.href);
        useReact(url.pathname + url.search);
    } else console.error('clicked on a tab with ' + target);
}

export function useReact(path?: string) {
    const popupContents = document.getElementsByClassName('popup-content');
    if (popupContents.length > 0) {
        const popupContent = popupContents[0];
        if (popupContent instanceof HTMLElement) popupContent.style.display = 'contents';
        popupContent.innerHTML = '';
        ReactDOM.render(
            <Router history={history}>
                <App />
            </Router>,
            popupContent,
            () => path && history.push(path),
        );
        popupContent.className = '';

        //remove buttons
        const popupButtons = document.getElementsByClassName('popup-buttons');
        if (popupButtons.length > 0) popupButtons[0].innerHTML = '';
    } else if (path) history.push(path);
}
