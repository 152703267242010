import styled, { css } from 'styled-components';

export interface ButtonProps {
    large?: boolean;
    mode?: 'success' | 'failure' | 'primary';
}

export const Button = styled.button<ButtonProps>`
    display: inline-flex;
    gap: 0.3em;
    align-items: center;
    justify-content: space-evenly;
    text-align: center;
    border: none;
    transition: background-color 0.5s ease, opacity 0.5s ease;
    outline: none;
    cursor: pointer;
    box-sizing: border-box;
    border-radius: 10px;
    font-size: inherit;
    height: 2.3em;
    min-height: 2.3em;
    min-width: 2.3em;
    padding: ${(props) => (props.large ? '0.6em 1.5em' : '0 0.5em')};
    margin: 0;
    font-weight: bold;

    ${({ mode }) => {
        switch (mode) {
            case 'success':
                return css`
                    background-color: green;
                    border: solid 1px green;
                    color: white;

                    :not(:disabled):hover {
                        background-color: darkgreen;
                    }
                `;
            case 'failure':
                return css`
                    background-color: red;
                    border: solid 1px red;
                    color: white;

                    :not(:disabled):hover {
                        background-color: darkred;
                    }
                `;
            case 'primary':
                return css`
                    background-color: var(--primary-color);
                    border: solid 1px var(--primary-color);
                    color: white;

                    :not(:disabled):hover {
                        background-color: var(--primary-variant-color);
                    }

                    &:disabled {
                        background-color: transparent;
                        color: rgba(128, 128, 128, 0.45);
                        border: var(--border);
                        opacity: 0.5;
                    }
                `;
            default:
                return css`
                    background-color: white;
                    color: var(--text-color);
                    border: var(--border);

                    :not(:disabled):hover {
                        background-color: var(--primary-back-color);
                    }
                `;
        }
    }};
    :disabled {
        cursor: not-allowed;
    }
`;

export const RoundButton = styled.div`
    width: 2.3em;
    height: 2.3em;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 1em;
    transition: background-color 0.5s ease;
    &:hover {
        background-color: rgba(0, 0, 0, 0.1);
    }
`;
