import React, { FC, FormEvent, ReactNode, useEffect, useState } from 'react';
import ReactDatePicker from 'react-datepicker';
import {
    CustomListEnum,
    CustomListItem,
    Transaction,
    TransactionGroupViewModel,
    Api,
    useApi,
    TransactionType,
} from '../api-client';
import { Button } from '../lib/Button';
import { FormGroup, FormStatus } from '../lib/Form';
import { SaveButton } from '../lib/Form/SaveButton';
import { Body, Footer, Header, Page, Title } from '../lib/Page';
import { Table, Types } from '../lib/Table';
import { RecursivePartial } from '../lib/util';

const schoolUserId = 40; // TODO create a school user and a method to get its id

const typeToList: Record<TransactionType, CustomListEnum> = {
    [TransactionType.Credit]: CustomListEnum.CreditItems,
    [TransactionType.Donation]: CustomListEnum.DonationItems,
    [TransactionType.Invoice]: CustomListEnum.InvoiceItems,
    [TransactionType.Payment]: CustomListEnum.PaymentItems,
    [TransactionType.Pledge]: CustomListEnum.PledgeItems,
};

interface TransactionGroupFormProps {
    invoiceNumber?: number;
    userId: number;
    transactionType: TransactionType;
}

export const TransactionGroupForm: FC<TransactionGroupFormProps> = ({ invoiceNumber, userId, transactionType }) => {
    const [value, onChange] = useState(
        (): RecursivePartial<TransactionGroupViewModel> => ({
            entryDate: new Date().toISOString(),
            dueDate: new Date().toISOString(),
            items: [{ commodityValue: 1 }],
            transactionType,
            transactor: { id: schoolUserId },
            transactee: { id: userId },
        }),
    );
    const [formStatus, setFormStatus] = useState<FormStatus>('no-change');
    const [focusedRow, setFocusedRow] = useState<number>();
    const [availableCommodities, setAvailableCommodities] = useState<CustomListItem[]>();
    const [idEditable, setIdEditable] = useState(false);

    useApi(
        async (api, params) => {
            if (invoiceNumber) {
                const { data } = await api.transactions.getTransactionGroup(invoiceNumber, params);
                onChange(data);
            } else {
                const { data } = await api.transactions.lastTransactionId({ transactionType });
                if (data) onChange({ ...value, id: data + 1 });
                else setIdEditable(true);
            }

            const { data } = await api.lookup.getCustomList({ customList: typeToList[transactionType] }, params);
            setAvailableCommodities(data);
        },
        [invoiceNumber],
    );

    const handleSubmit = async () => {
        setFormStatus('saving');
        // TODO validation
        try {
            const data = value as TransactionGroupViewModel; // HACK
            if (invoiceNumber) {
                await Api.transactions.editTransactionGroup(data);
            } else {
                await Api.transactions.createTransactionGroup(data);
            }
            setFormStatus('saved');
        } catch (e) {
            setFormStatus('error');
        }
    };

    const handleChange = (value: RecursivePartial<TransactionGroupViewModel>) => {
        onChange(value);
        setFormStatus('changed');
    };

    const handleItemDelete = (record: RecursivePartial<TransactionGroupViewModel>) => {
        handleChange({ ...value, items: value?.items?.filter((x) => x != record) });
    };

    return (
        <Page
            as="form"
            onSubmit={(e: FormEvent<HTMLFormElement>) => {
                e.preventDefault();
                handleSubmit();
            }}
        >
            <Header>
                <Title>
                    {!invoiceNumber && 'New '}
                    {TransactionType[transactionType]}
                    {' #'}
                    {invoiceNumber || value?.id || ''}
                </Title>
            </Header>
            <Body>
                <div className="form-section">
                    {idEditable && (
                        <FormGroup label="Number">
                            <input
                                type="number"
                                value={value?.id || ''}
                                onChange={(e) => handleChange({ ...value, id: +e.currentTarget.value })}
                            />
                        </FormGroup>
                    )}
                    <FormGroup label="Entry Date">
                        <ReactDatePicker
                            selected={value?.entryDate ? new Date(value.entryDate) : new Date()}
                            onChange={(d) =>
                                d instanceof Date && handleChange({ ...value, entryDate: d.toISOString() })
                            }
                        />
                    </FormGroup>
                    <FormGroup label="Due Date">
                        <ReactDatePicker
                            selected={value?.dueDate ? new Date(value.dueDate) : new Date()}
                            onChange={(d) => d instanceof Date && handleChange({ ...value, dueDate: d.toISOString() })}
                        />
                    </FormGroup>
                    <FormGroup label="Status">???</FormGroup>
                    <Table
                        columns={[
                            {
                                name: 'commodity',
                                title: 'Item',
                                type: Types.ref,
                                available: Object.fromEntries(
                                    (availableCommodities || []).map((x) => [x.id, x.name || '']),
                                ),
                            },
                            { name: 'commodityValue', title: 'Amount', type: Types.number },
                            { name: 'quantity', title: 'Quantity', type: Types.number },
                        ]}
                        records={value?.items || []}
                        getPrimaryKey={(x, i) => x?.id?.toString() || `new`}
                        onRecordChange={(x, i) =>
                            handleChange({ ...value, items: Object.assign([], value?.items || [], { [i]: x }) })
                        }
                        actionsGenerator={(record) => (
                            <>
                                <Button className="fa fa-trash" onClick={() => handleItemDelete(record)} />
                            </>
                        )}
                        focusedRow={focusedRow}
                    />
                    <Button
                        type="button"
                        mode="primary"
                        onClick={(e) => {
                            e.preventDefault();
                            setFocusedRow(value?.items?.length || 0);
                            handleChange({ ...(value || {}), items: [...(value?.items || []), {}] });
                        }}
                    >
                        Add
                    </Button>
                    <span>
                        Total:{' '}
                        {(value?.items || []).reduce((a, b) => a + (b?.commodityValue || 0) * (b?.quantity || 0), 0)}
                    </span>
                </div>
            </Body>
            <Footer>
                <SaveButton formStatus={formStatus} />
            </Footer>
        </Page>
    );
};
