import React, { FC, FormEvent, Fragment, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { Table } from '..';
import { Api, StudentTranscriptsIndexViewModel, StudentTranscriptViewModel, TemplateType, useApi } from '../api-client';
import { ActionsMenu } from '../lib/ActionsMenu/ActionsMenu';
import { Button } from '../lib/Button';
import { CustomSection } from '../lib/CustomFields';
import { FormStatus } from '../lib/Form';
import { Body, Footer, Header, Page, Title } from '../lib/Page';
import { Spinner } from '../lib/Spinner';
import { Column, Types } from '../lib/Table';
import { TemplateSelect } from '../lib/template-select';

const pascalCaseRegex = /.([A-Z])/g;
export interface TranscriptsProps {
    studentId: number;
}
export const Transcripts: FC<TranscriptsProps> = ({ studentId }) => {
    const [viewModel, setViewModel] = useState<StudentTranscriptsIndexViewModel>();
    const [formStatus, setFormStatus] = useState<FormStatus>('loading');

    const { fetchData } = useApi(async (api, params) => {
        setFormStatus('loading');
        const { data: result } = await api.students.transcripts({ studentId }, params);
        setViewModel(result);
        setFormStatus('no-change');
    }, []);

    async function deleteTranscript(...filenames: string[]) {
        const n = filenames.length;
        // @ts-ignore // TODO
        await modals.confirm(`Are you sure you want to delete ${n} transcript${n > 1 ? 's' : ''}?`, { delete: true });
        // TODO do not redirect
        window.location.href = '/Students/DeleteTranscript?' + filenames.map((name) => `filenames=${name}`).join('&');
    }

    function emailSelectedTranscript() {
        const filenames = Object.values(selectedRecords)
            .map((x) => `attCurName=${x.filename}&attOrgName=${x.name}.${x.filename.split('.').pop()}`)
            .join('&');
        const queryString = `forStudents=true&ids=${studentId}&attFolderName=transcripts&${filenames}`;
        window.location.href = '/email/send?' + queryString;
    }

    const handleFileUpload = async (e: FormEvent<HTMLInputElement>) => {
        const files = e.currentTarget.files;
        if (files) {
            setFormStatus('loading');
            await Api.students.uploadTranscripts(
                {
                    files: [...files],
                },
                { studentId },
            );
            fetchData();
        }
    };

    const getPrimaryKey = (record: StudentTranscriptViewModel) => record.filename;

    const [selectTemplate, setSelectTemplate] = useState(false);
    const [selectedRecords, setSelectedRecords] = useState<Record<string, StudentTranscriptViewModel>>({});

    return (
        <Page>
            {selectTemplate && (
                <div style={{ display: 'block' }} className="modal">
                    <TemplateSelect
                        studentIds={[studentId]}
                        onClose={() => {
                            setSelectTemplate(false);
                            fetchData();
                        }}
                    />
                </div>
            )}
            <Header>
                <Title>Transcripts {formStatus === 'loading' && <Spinner />}</Title>
                <div style={{ flex: '1 1 auto' }}></div>
                {viewModel?.hasEditPermission && (
                    <>
                        <Button as="label" mode="primary">
                            <i className="fa fa-file-upload"></i>
                            Upload
                            <input type="file" name="files" onChange={handleFileUpload} multiple hidden />
                        </Button>
                        <Button mode="primary" onClick={() => setSelectTemplate(true)}>
                            <i className="fa fa-plus"></i>
                            Issue
                        </Button>
                    </>
                )}
                {viewModel?.hasEditPermission && (
                    <ActionsMenu>
                        <button onClick={() => deleteTranscript(...Object.keys(selectedRecords))}>
                            <i className="fa fa-trash"></i>
                            <span>Delete</span>
                        </button>
                        {viewModel?.hasEmailFeature && (
                            <button onClick={() => emailSelectedTranscript()}>
                                <i className="fa fa-envelope"></i>
                                <span>Email</span>
                            </button>
                        )}
                    </ActionsMenu>
                )}
            </Header>
            <Body>
                <Table
                    selectedRecords={selectedRecords}
                    onRecordSelectionChange={setSelectedRecords}
                    getPrimaryKey={getPrimaryKey}
                    actionsGenerator={(x) => (
                        <>
                            <Button
                                className="fa fa-external-link-alt"
                                as="a"
                                target="_blank"
                                href={`/uploads/transcripts/${x.filename}`}
                            />
                            {viewModel?.hasEditPermission ? (
                                <Button
                                    className="btn btn-default fa fa-trash"
                                    onClick={() => deleteTranscript(x.filename)}
                                />
                            ) : null}
                            {viewModel?.hasEditPermission && viewModel?.hasEmailFeature ? (
                                <Button
                                    className="fa fa-envelope"
                                    as="a"
                                    href={`/email/send?forstudents=true&ids=&attFolderName=transcripts&attCurName=${
                                        x.filename
                                    }&attOrgName=${x.name + '.' + x.filename.split('.').pop()}`}
                                />
                            ) : null}
                        </>
                    )}
                    columns={[
                        { title: 'Template Name', name: 'template', type: Types.ref },
                        {
                            title: 'Template Type',
                            name: 'templateType',
                            type: Types.ref,
                            available: Object.fromEntries(
                                Object.entries(TemplateType).map(([k, v]) => [
                                    k,
                                    typeof v === 'string' ? v.replaceAll(pascalCaseRegex, ' $1') : v,
                                ]),
                            ),
                        },
                        { title: 'File Name', name: 'name', type: Types.string },
                        { title: 'Issue Date', name: 'issueDate', type: Types.date },
                    ]}
                    records={viewModel?.studentTranscripts || []}
                />
                {viewModel?.sections && (
                    <form method="POST" id="main-form">
                        {viewModel.sections.map((section) => (
                            <CustomSection
                                key={section.name}
                                section={section}
                                fieldValues={viewModel?.customFieldValues}
                            />
                        ))}
                    </form>
                )}
            </Body>
            {viewModel?.sections && (
                <Footer>
                    <Button large mode="primary" type="submit" form="main-form">
                        Save
                    </Button>
                    <Button large as="a" href={`/students/edit/${studentId}`}>
                        Cancel
                    </Button>
                </Footer>
            )}
        </Page>
    );
};
