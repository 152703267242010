import { FC, useMemo, useState } from 'react';
import { CustomListItem, MealAdditionalDay, SettingsCustomizeModel } from '../api-client';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { CustomList } from './CustomList';
import { BindingProps, NullableBindingProps, useErrors } from '../lib/Form';
import { RecursivePartial } from '../lib/util';
import { CustomizePageViewModel } from '.';

const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
interface MealOptionsProps extends NullableBindingProps<CustomizePageViewModel> {
    mealKindsCustomList: BindingProps<
        RecursivePartial<CustomListItem>[],
        RecursivePartial<CustomListItem>[],
        { [id: number]: RecursivePartial<CustomListItem> }
    >;
}
export const MealOptions: FC<MealOptionsProps> = ({ value: model, onChange: onModelChange, mealKindsCustomList }) => {
    const mealKindMap = useMemo(
        () => Object.fromEntries((model?.mealKinds || []).map(({ id, name }) => [id, name])),
        [model?.mealKinds],
    );

    return (
        <div className="form-section">
            <CustomList
                {...mealKindsCustomList}
                listName="MealKinds"
                tooltip={`If your school serves meals, enter the meal types here, like:  Breakfast, Lunch, etc.   You can then track the meals via the report \"Meal Report\".  (If you don't serve meals, leave this blank).`}
            />
            <style>
                {`
                #new-meal-add-day { margin-top: 20px; }
                #new-meal-add-day-kind, #new-meal-add-day-day { margin-right: 20px; }
                #meal-add-days .rm { cursor: pointer; }`}
            </style>
            <div className="form-group" id="days-of-week-w">
                <label className="control-label">Meals served on:</label>
                <div className="field-w">
                    {[...Array(6).keys()].map((day) => {
                        return (
                            <label key={day} className="chk-lbl day-of-week">
                                <input
                                    type="checkbox"
                                    name="MealDaysOfWeek"
                                    defaultValue={day}
                                    checked={model?.mealDaysOfWeek?.includes(day)}
                                    onChange={(e) =>
                                        model &&
                                        onModelChange({
                                            ...model,
                                            mealDaysOfWeek: e.currentTarget.checked
                                                ? [...(model.mealDaysOfWeek || []), day]
                                                : model.mealDaysOfWeek?.filter((x) => x != day),
                                        })
                                    }
                                />
                                {daysOfWeek[day]}
                            </label>
                        );
                    })}
                </div>
            </div>
            <h4>Additional days to serve meals</h4>
            <table className="table" id="meal-add-days">
                <thead>
                    <tr>
                        <th>Meal</th>
                        <th>Day</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {model?.mealAdditionalDays && model.mealAdditionalDays.length > 0 ? (
                        model.mealAdditionalDays.map((additionalDay, ix) => (
                            <tr key={ix}>
                                <td>
                                    {additionalDay.kind?.name ||
                                        (additionalDay.kindID && mealKindMap[additionalDay.kindID])}
                                </td>
                                <td>{additionalDay.date ? new Date(additionalDay.date).toLocaleDateString() : null}</td>
                                <td>
                                    <span
                                        className="rm"
                                        onClick={() =>
                                            onModelChange({
                                                ...model,
                                                mealAdditionalDays: model.mealAdditionalDays?.filter(
                                                    (x) => x != additionalDay,
                                                ),
                                            })
                                        }
                                    >
                                        X
                                    </span>
                                    <input
                                        type="hidden"
                                        name={`MealAdditionalDays[${ix}].ID`}
                                        value={additionalDay.id}
                                    />
                                    <input
                                        type="hidden"
                                        name={`MealAdditionalDays[${ix}].KindID`}
                                        value={additionalDay.kindID}
                                    />
                                    <input
                                        type="hidden"
                                        name={`MealAdditionalDays[${ix}].Date`}
                                        value={additionalDay.date}
                                    />
                                    <input type="hidden" name={`MealAdditionalDays.Index`} value={ix} />
                                </td>
                            </tr>
                        ))
                    ) : (
                        <tr>
                            <td colSpan={2}>No additional days.</td>
                        </tr>
                    )}
                </tbody>
            </table>
            <NewMealAddDay
                mealKinds={model?.mealKinds || undefined}
                onAdd={(kindID, date) =>
                    model &&
                    onModelChange({
                        ...model,
                        mealAdditionalDays: [...(model?.mealAdditionalDays || []), { kindID, date, id: 0 }],
                    })
                }
            />
        </div>
    );
};

interface NewMealAddDayProps {
    mealKinds: RecursivePartial<CustomListItem>[] | undefined;
    onAdd: (mealKind: number, day: string) => void;
}

const NewMealAddDay: FC<NewMealAddDayProps> = ({ mealKinds, onAdd }) => {
    const [date, setDate] = useState<string>();
    const [mealKind, setMealKind] = useState<number>();
    return (
        <div className="two-form-groups" id="new-meal-add-day">
            <div className="form-group">
                <label className="control-label">Meal:</label>
                <div className="field-w">
                    <select
                        id="new-meal-add-day-kind"
                        value={mealKind || ''}
                        onChange={(e) => setMealKind(+e.currentTarget.value || undefined)}
                    >
                        <option value=""></option>
                        {mealKinds?.map(({ id, name }) => (
                            <option key={id} value={id}>
                                {name}
                            </option>
                        ))}
                    </select>
                </div>
            </div>
            <div className="form-group">
                <label className="control-label">Day:</label>
                <div className="field-w">
                    <DatePicker
                        value={date}
                        onChange={(date) => !Array.isArray(date) && setDate(date?.toLocaleDateString())}
                        id="new-meal-add-day-day"
                    />
                </div>
            </div>
            <button
                type="button"
                className="btn btn-run icon-new"
                id="new-meal-add-day-add"
                onClick={() => {
                    if (mealKind && date) {
                        onAdd(mealKind, date);
                        setDate(undefined);
                        setMealKind(undefined);
                    }
                }}
                disabled={!mealKind || !date}
            >
                Add
            </button>
        </div>
    );
};
