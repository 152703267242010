import React, { FC, FormEvent, useEffect, useState } from 'react';
import {
    CustomFieldType,
    CustomListEnum,
    CustomListItem,
    SettingsCustomizeModel,
    Api,
    Year,
    useApi,
} from '../api-client';
import { Accordion } from '../lib/Accordion';
import { Button } from '../lib/Button';
import { FormStatus, ObjectValidity, useErrors, Validity } from '../lib/Form';
import { FormFooterProps, SaveButton } from '../lib/Form/SaveButton';
import { Body, Footer, Header, Page, Title } from '../lib/Page';
import { Popup } from '../lib/Popup';
import { Spinner } from '../lib/Spinner';
import { RecursivePartial } from '../lib/util';
import { CustomList } from './CustomList';
import { EmailOptions } from './EmailOptions';
import { MealOptions } from './MealOptions';
import { SchoolOptions } from './SchoolOptions';
import { TranscriptsOptions } from './TranscriptsOptions';

function serializeCustomListData(items: RecursivePartial<CustomListItem>[]) {
    return JSON.stringify(items?.map(({ id, name }, i) => ({ id, name, orderBy: i })));
}

export type PropErrors = string;

export interface CustomizePageViewModel extends SettingsCustomizeModel {
    customListsDict: { [L in CustomListEnum]?: Partial<CustomListItem>[] };
}

export const CustomizePage: FC = () => {
    const [model, setModel] = useState<CustomizePageViewModel>();

    const customListsDict = model?.customListsDict || {};
    const setCustomListsDict = (value: CustomizePageViewModel['customListsDict']) =>
        model && setModel({ ...model, customListsDict: value });

    const [formStatus, setFormStatus] = useState<FormStatus>('loading');

    const { track, isInvalid } = useErrors<CustomizePageViewModel>({});

    const { track: trackCustomLists, setModelValidity: setCustomListsErrors } = useErrors(track('customListsDict'));

    useApi(async (api, params) => {
        setFormStatus('loading');
        const { data: result } = await api.settings.customize(params);
        setModel({
            ...result,
            customListsDict: Object.fromEntries(
                (result.customListItems || []).map((x) => {
                    if (!x.key) throw new ReferenceError();
                    return [x.key, x.value || []];
                }),
            ),
        });
        setFormStatus('no-change');
    }, []);
    useEffect(() => {
        const controller = new AbortController();
        async function fetchData() {}

        fetchData();
        return () => controller.abort();
    }, []);

    // warn before leaving
    const [popup, setPopup] = useState<{ onFinish: () => void }>();
    useEffect(() => {
        const handleClick = (e: Event) => {
            // get state from event handler
            setFormStatus((changesStatus) => {
                if (changesStatus === 'changed') {
                    e.preventDefault();
                    setPopup({
                        onFinish: () => {
                            if (e.target instanceof HTMLAnchorElement) {
                                window.location.href = e.target.href;
                            }
                        },
                    });
                }
                return changesStatus;
            });
        };

        const links = document.getElementsByTagName('a');
        for (let index = 0; index < links.length; index++) {
            links[index].addEventListener('click', handleClick);
        }

        return () => {
            const links = document.getElementsByTagName('a');
            for (let index = 0; index < links.length; index++) {
                links[index].removeEventListener('click', handleClick);
            }
        };
    }, []);

    const handleModelChange = (model: CustomizePageViewModel) => {
        setFormStatus('changed');
        setModel(model);
    };

    const handleCustomListChange = (listId: CustomListEnum, items: RecursivePartial<CustomListItem>[]) => {
        setFormStatus('changed');
        setCustomListsDict({ ...customListsDict, [listId]: items });
    };

    /** @returns true if the custom lists are valid, otherwise false. */
    const validateCustomLists = (): boolean => {
        const customListsErrors = Object.entries(customListsDict).flatMap(([key, x]): [
            string,
            { [id: number]: string },
        ][] => {
            const itemErrors = (x || []).flatMap((x): [number, string][] =>
                !x.id || x.name ? [] : [[x.id, 'name required']],
            );
            if (itemErrors.length > 0) {
                return [[key, Object.fromEntries(itemErrors)]];
            } else return [];
        });
        if (customListsErrors.length > 0) {
            setCustomListsErrors(Object.fromEntries(customListsErrors));
            setFormStatus('invalid');
            return false;
        } else {
            setCustomListsErrors({});
            return true;
        }
    };

    const handleSubmit = async () => {
        if (model) {
            if (validateCustomLists()) {
                setFormStatus('saving');
                // HACK because of backend legacy, SettingsCustomizeModel.customListsData should be filled with customListItems on submit, despite being initially empty
                // TODO validation method that is a type guard from Partial<SettingsCustomizeModel> to non-nullable.
                const processedModel = {
                    ...model,
                    customListsData: Object.entries(customListsDict).map(([listID, items]) => ({
                        listID: +listID as CustomListEnum,
                        data: serializeCustomListData(items || []),
                    })),
                } as SettingsCustomizeModel;
                try {
                    await Api.settings.customize2(processedModel);
                    setFormStatus('saved');
                } catch (e) {
                    setFormStatus('error');
                }
            }
        }
    };

    return (
        <Page
            as="form"
            onSubmit={(e: FormEvent<HTMLFormElement>) => {
                e.stopPropagation();
                e.preventDefault();
                handleSubmit();
            }}
        >
            <Header>
                <Title>Customize {formStatus === 'loading' ? <Spinner /> : null}</Title>
            </Header>
            {popup ? (
                <Popup
                    onYes={async () => {
                        await handleSubmit();
                        popup.onFinish();
                        setPopup(undefined);
                    }}
                    onNo={() => {
                        popup.onFinish();
                        setPopup(undefined);
                    }}
                    onClose={() => setPopup(undefined)}
                />
            ) : undefined}
            <Body noPadding>
                <Accordion
                    defaultOpenSection="School Options"
                    sections={{
                        'School Options': {
                            children: (
                                <SchoolOptions
                                    value={model?.school}
                                    onChange={(school) => model && handleModelChange({ ...model, school })}
                                    years={model?.years || new Array<Year>()}
                                    {...track('school')}
                                />
                            ),
                        },
                        'CRM Fields Options': {
                            children: (
                                <>
                                    <CustomList
                                        {...trackCustomLists(CustomListEnum.PhoneTypes)}
                                        fieldType={CustomFieldType.PhoneNums}
                                        listName="Phone Number Types"
                                        value={customListsDict[CustomListEnum.PhoneTypes] || []}
                                        onChange={(items) => handleCustomListChange(CustomListEnum.PhoneTypes, items)}
                                        tooltip="Phone types help you stay organized clarifying whose phone number is listed.  You can have as many types as you need, like: Parents Cell, Parents Home, Emergency Contact, Grandparents, Family Doctor, etc."
                                    />
                                    <CustomList
                                        {...trackCustomLists(CustomListEnum.EmailTypes)}
                                        fieldType={CustomFieldType.Emails}
                                        listName="Email Types"
                                        value={customListsDict[CustomListEnum.EmailTypes] || []}
                                        onChange={(items) => handleCustomListChange(CustomListEnum.EmailTypes, items)}
                                        tooltip="Email types help you stay organized clarifying whose email is listed.  You can have as many types as you need, like: Father's Email, Mother's Email, Emergency Contact, Grandparents, Family Doctor, etc"
                                    />
                                    <CustomList
                                        {...trackCustomLists(CustomListEnum.EventTypes)}
                                        listName="Event Types"
                                        value={customListsDict[CustomListEnum.EventTypes] || []}
                                        onChange={(items) => handleCustomListChange(CustomListEnum.EventTypes, items)}
                                        tooltip="Event types help you keep your school calendar organized by grouping events into categories.  You can have as many types as you need, like:  Trips, Holiday, Graduation, etc.  (If you don't want to use events on your calendar, leave this blank)."
                                    />
                                </>
                            ),
                        },
                        'Financial Fields Options': {
                            children: (
                                <>
                                    {[
                                        {
                                            customList: CustomListEnum.StudentAccountStatuses,
                                            name: 'Student Account Statuses',
                                            tooltip: `The student \"Account Status\" is located in the student's \"Payment Tab\" (and is also displayed in the Transcript Report page), and helps you track the overall account status of a student, like:  Open, Closed, Delinquent, Collections, etc.  You must have at least one Account Status type.`,
                                        },
                                        { customList: CustomListEnum.InvoiceItems, name: 'Invoice Items' },
                                        { customList: CustomListEnum.PaymentItems, name: 'Payment Items' },
                                        { customList: CustomListEnum.CreditItems, name: 'Credit Items' },
                                        { customList: CustomListEnum.PledgeItems, name: 'Pledge Items' },
                                        { customList: CustomListEnum.DonationItems, name: 'Donation Items' },
                                    ].map(({ customList, tooltip, name }) => (
                                        <CustomList
                                            key={customList}
                                            {...trackCustomLists(customList)}
                                            listName={name}
                                            value={customListsDict[customList] || []}
                                            onChange={(items) => handleCustomListChange(customList, items)}
                                            tooltip={tooltip}
                                        />
                                    ))}
                                </>
                            ),
                        },
                        'Email Settings': {
                            children: model?.school ? (
                                <EmailOptions
                                    {...track('school')}
                                    value={model.school}
                                    onChange={(school) => handleModelChange({ ...model, school })}
                                    emailReplyTos={customListsDict[CustomListEnum.EmailReplyTos] || []}
                                    onEmailReplyTosChange={(items) =>
                                        handleCustomListChange(CustomListEnum.EmailReplyTos, items)
                                    }
                                />
                            ) : null,
                        },
                        'Meal Options': {
                            description:
                                'The Meal Report automatically generates meals based on the days you serve meals.  Here you select the days of the week that meals are served.  (If you have meals that are not served weekly, enter them manually in the next section "Additional days to serve meals").',
                            children: (
                                <MealOptions
                                    value={model}
                                    onChange={handleModelChange}
                                    mealKindsCustomList={{
                                        ...trackCustomLists(CustomListEnum.MealKinds),
                                        value: customListsDict[CustomListEnum.MealKinds] || [],
                                        onChange: (items) => handleCustomListChange(CustomListEnum.MealKinds, items),
                                    }}
                                />
                            ),
                        },
                    }}
                />
            </Body>
            <Footer>
                <SaveButton formStatus={isInvalid ? 'invalid' : formStatus} />
                <Button large as="a" href="/settings/schoolinfo">
                    Cancel
                </Button>
            </Footer>
        </Page>
    );
};
