import styled from 'styled-components';
import { Column, Types } from '.';
import { ExpressionNode } from '../../api-client';
import { Input } from '../Input';

const t: Record<string, Types> = {
    'System.DateTime': Types.date,
};
const t1: Record<Types, string> = {
    [Types.date]: 'System.DateTime',
    [Types.number]: 'System.Int32',
    [Types.decimal]: 'System.Decimal',
    [Types.date]: 'System.Object',
    [Types.string]: 'System.Object',
    [Types.ref]: 'System.Object',
    [Types.boolean]: 'System.Boolean',
};

export function _and(xpr1: ExpressionNode, xpr2: ExpressionNode): ExpressionNode {
    return {
        typeName: 'System.Boolean',
        methodName: 'AndAlso',
        parameters: [xpr1, xpr2],
    };
}

export function _propertyEquals(col: Column, paramType: string): ExpressionNode {
    return {
        typeName: 'System.Boolean',
        methodName: 'Equal',
        parameters: [
            {
                typeName: col.type,
                methodName: 'Property',
                parameters: [{ methodName: 'Parameter', typeName: paramType }],
                literalValueJson: JSON.stringify(col.name),
            },
            {
                methodName: 'Constant',
                typeName: t1[col.type],
                literalValueJson: 'null',
            },
        ],
    };
}

const DelButton = styled.button`
    display: none;
    margin-right: -1em;
    z-index: 10;
`;
const StyledExprView = styled.div`
    display: contents;
    position: relative;

    &:hover > ${DelButton} {
        display: inline-block;
    }
`;

interface ExprViewProps {
    param: ExpressionNode;
    onParameterChange: (param: ExpressionNode | undefined) => void;
}

export function ExprView(props: ExprViewProps): JSX.Element {
    return (
        <StyledExprView>
            {props.param?.typeName === t1[Types.boolean] &&
                !props.param.parameters?.every((p) => p?.typeName === t1[Types.boolean]) && (
                    <DelButton className="fa fa-times" onClick={() => props.onParameterChange(undefined)} />
                )}
            <InnerExprView {...props} />
        </StyledExprView>
    );
}

const StyledEqualTop = styled.span`
    font-size: small;
    display: flex;
    gap: 0.3em;
`;

const StyledEqual = styled.div`
    display: inline-flex;
    flex-direction: column;
`;

const StyledOperator = styled.span`
    color: var(--primary-color);
`;

function InnerExprView({ param: parameter, onParameterChange }: ExprViewProps): JSX.Element {
    function handleAndAlsoArgumentChange(p: ExpressionNode | undefined, i: number) {
        const parameters = p
            ? Object.assign([], parameter.parameters || [], {
                  [i]: p,
              })
            : parameter.parameters?.splice(i, 1) || [];
        onParameterChange(
            parameters.length > 1
                ? {
                      ...parameter,
                      parameters,
                  }
                : parameters.length > 0
                ? parameters[0]
                : undefined,
        );
    }

    if (parameter) {
        if (parameter.methodName) {
            switch (parameter.methodName) {
                case 'Parameter':
                    return <></>;
                case 'Property':
                    const expr = parameter.parameters?.[0];
                    return (
                        <div>
                            {expr && (
                                <ExprView
                                    param={expr}
                                    onParameterChange={(p) =>
                                        onParameterChange(
                                            p
                                                ? {
                                                      ...parameter,
                                                      parameters: [p],
                                                  }
                                                : undefined,
                                        )
                                    }
                                />
                            )}
                            <span>{JSON.parse(parameter.literalValueJson || 'null')}</span>
                        </div>
                    );
                case 'Constant':
                    return (
                        <Input
                            type={t[parameter.typeName || '']}
                            value={JSON.parse(parameter.literalValueJson || '')}
                            onChange={(v) =>
                                onParameterChange({
                                    ...parameter,
                                    literalValueJson: JSON.stringify(v),
                                })
                            }
                        />
                    );
                case 'AndAlso':
                    return (
                        <>
                            {parameter.parameters?.map((x, i) => (
                                <ExprView
                                    key={i}
                                    param={x}
                                    onParameterChange={(p) => handleAndAlsoArgumentChange(p, i)}
                                />
                            ))}
                        </>
                    );
                case 'GreaterThan':
                case 'LessThan':
                case 'Equal':
                    return (
                        <StyledEqual>
                            <StyledEqualTop>
                                {parameter.parameters?.[0] && (
                                    <ExprView
                                        param={parameter.parameters[0]}
                                        onParameterChange={(p) =>
                                            p &&
                                            onParameterChange({
                                                ...parameter,
                                                parameters: [p, parameter.parameters?.[1]!],
                                            })
                                        }
                                    />
                                )}
                                <StyledOperator>{operatorDisplayName(parameter)}</StyledOperator>
                            </StyledEqualTop>
                            {parameter.parameters?.[1] && (
                                <ExprView
                                    param={parameter.parameters?.[1]}
                                    onParameterChange={(p) =>
                                        p &&
                                        onParameterChange({ ...parameter, parameters: [parameter.parameters?.[0]!, p] })
                                    }
                                />
                            )}
                        </StyledEqual>
                    );
            }
            return (
                <input
                    value={parameter.methodName ?? ''}
                    onChange={(e) => onParameterChange({ ...parameter, methodName: e.currentTarget.value })}
                />
            );
        }
    }
    return <code>{JSON.stringify(parameter)}</code>;
}

function operatorDisplayName(parameter: ExpressionNode): string {
    if (parameter.parameters?.[0].typeName === 'System.DateTime') {
        if (parameter.methodName === 'GreaterThan') return 'After';
        if (parameter.methodName === 'LessThan') return 'Before';
    }
    if (parameter.methodName === 'Equal') return 'is';
    return parameter.methodName || '';
}
