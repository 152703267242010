import { FC } from 'react';

export interface PopupProps {
    onClose: () => void;
    onYes: () => void;
    onNo: () => void;
}

export const Popup: FC<PopupProps> = ({ onClose, onNo, onYes }) => (
    <div className="jquery-popup">
        <div className="o"></div>
        <div className="c blue">
            <div className="a">
                <div className="close"></div>
                <img src="/res/img/popup/icon-disk.png" />
                <div className="t">Want to save your changes for this tab?</div>
            </div>
            <div className="b">
                <div className="btns">
                    <button className="btn highlight" onClick={onYes}>
                        Yes
                    </button>
                    <button className="btn highlight" onClick={onNo}>
                        No
                    </button>
                    <button className="btn no-highlight" onClick={onClose}>
                        Cancel
                    </button>
                </div>
            </div>
        </div>
    </div>
);
