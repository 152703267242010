import { FC, FormEvent, useEffect } from 'react';
import { useState } from 'react';
import { IDName, TemplateDescription, TemplateType, useApi } from '../api-client';

/**
 * Created manually to represent a server response
 */
interface StudentTranscript {
    studentID: number;
    name: string;
    filename: string;
    templateID: number | null;
    /** @type Date */
    issueDate: string;
}

const ACTION = '/templates/render';

interface TemplateSelectProps {
    studentIds: number[];
    onClose: () => void;
}

export const TemplateSelect: FC<TemplateSelectProps> = ({ studentIds, onClose }) => {
    const [studentTemplates, setStudentTemplates] = useState<TemplateDescription[]>();
    const [studentYears, setStudentYears] = useState<IDName[]>();
    const [saveLoading, setSaveLoading] = useState(false);
    const [errorHtml, setErrorHtml] = useState<string>();

    useApi(
        async (api, params) => {
            const { data: studentTemplates } = await api.templates.studentTemplates(studentIds, params);
            setStudentTemplates(studentTemplates);
            const { data: studentYears } = await api.templates.studentsYears(studentIds, params);
            setStudentYears(studentYears);
        },
        [studentIds],
    );

    const handleSave = async (e: FormEvent<HTMLButtonElement>, email?: boolean) => {
        e.preventDefault();
        const form = e.currentTarget.form;
        if (form && form.reportValidity()) {
            setSaveLoading(true);
            const formData = new FormData(form);
            const resp = await fetch(ACTION, {
                method: 'POST',
                body: formData,
            });
            if (resp.ok) {
                if (email) {
                    // open send email page
                    const studentTranscripts: StudentTranscript[] = await resp.json();
                    for (const studentTranscript of studentTranscripts) {
                        window.open(
                            `/email/send?` +
                                `forStudents=true&` +
                                `ids=${studentTranscript.studentID}&` +
                                `attOrgName=${
                                    studentTranscript.name
                                        ? studentTranscript.name + '.pdf'
                                        : studentTranscript.filename
                                }&` +
                                `attCurName=${studentTranscript.filename}&` +
                                `attFolderName=transcripts`,
                        );
                    }
                }
                onClose();
            } else {
                const errorHtml = await resp.text();
                setErrorHtml(errorHtml || 'an error occurred.');
            }
            setSaveLoading(false);
        }
    };

    return (
        <div className="modal-dialog">
            <form action={ACTION} method="POST" target="_blank" className="modal-content main-form">
                <div className="modal-header">
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={onClose}>
                        <i className="fa fa-times"></i>
                    </button>
                    <h4 className="modal-title">Issue Transcript</h4>
                </div>
                {errorHtml ? (
                    <div dangerouslySetInnerHTML={{ __html: errorHtml }} />
                ) : (
                    <>
                        <div className="modal-body">
                            {studentIds.map((id) => (
                                <input key={id} defaultValue={id} name="StudentIDs" type="hidden" />
                            ))}
                            <div className="form-section">
                                <label>Select Template</label>
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th></th>
                                            <th>Template Name</th>
                                            <th>Type</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {studentTemplates ? (
                                            studentTemplates.map((template) => {
                                                return (
                                                    <tr key={template.id}>
                                                        <td>
                                                            <input
                                                                type="radio"
                                                                name="TemplateID"
                                                                value={template.id}
                                                                required
                                                            />
                                                        </td>
                                                        <td>{template.name}</td>
                                                        <td>
                                                            {template.type
                                                                ? TemplateType[template.type].replace(
                                                                      // Pascal Case to space
                                                                      /(.)([A-Z])/g,
                                                                      '$1 $2',
                                                                  )
                                                                : ''}
                                                        </td>
                                                    </tr>
                                                );
                                            })
                                        ) : (
                                            <tr>
                                                <td>
                                                    <div className="loader" />
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                                <div className="form-group">
                                    <label className="control-label">Filter By Years</label>
                                    <div className="field-w">
                                        {studentYears ? (
                                            studentYears.map(({ id, name }) => (
                                                <label key={id}>
                                                    <input type="checkbox" name="YearID" value={id} defaultChecked />
                                                    {name}
                                                </label>
                                            ))
                                        ) : (
                                            <div className="loader" />
                                        )}
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label className="control-label">File Name</label>
                                    <div className="field-w">
                                        <input type="text" name="Name" required />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            {saveLoading ? <div className="loader" /> : null}
                            {/* <button className="btn btn-secondary" name="OutputType" value="html">
                                Preview Html
                            </button> */}
                            <button className="btn btn-secondary" name="OutputType" value="pdf">
                                Preview
                            </button>
                            <button className="btn btn-primary" onClick={handleSave}>
                                Save to Student
                            </button>
                            {studentIds.length === 1 ? (
                                <button className="btn btn-primary" onClick={(e) => handleSave(e, true)}>
                                    Save to Student &amp; Email
                                </button>
                            ) : null}
                        </div>
                    </>
                )}
            </form>
        </div>
    );
};
