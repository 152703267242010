import React, { FC, ReactNode, useState } from 'react';
import styled from 'styled-components';

export type Validity<T> = string | undefined | ObjectValidity<T>;

export type ObjectValidity<T> = T extends Array<infer ElementType>
    ? { [key: string]: Validity<ElementType> }
    : { [Prop in keyof T]?: Validity<T[Prop]> };
export interface ValidityProps<T> {
    onValidityChange?: (validity: Validity<T>) => void;
    modelValidity?: Validity<T>;
}
export interface BindingProps<T, S = T, Errs = T> extends ValidityProps<Errs> {
    value: T;
    onChange: (model: S) => void;
}
export interface NullableBindingProps<T, S = T, Errs = T> extends ValidityProps<Errs> {
    value: T | undefined;
    onChange: (model: S) => void;
}

export function useErrors<T>({ onValidityChange, modelValidity: upstreamModelValidity }: ValidityProps<T>) {
    // there is no need for a state if T is not an object
    const [modelValidity, setModelValidity] = useState<ObjectValidity<T>>();
    const [invalidPropCount, setInvalidPropCount] = useState<number>(0);

    const handleModelValidityChange = (value: ObjectValidity<T>) => {
        setInvalidPropCount(Object.values(value).filter((x) => !!x).length);
        setModelValidity(value);
    };

    const handleItemValidityChange = <K extends keyof ObjectValidity<T>>(
        prop: K,
        propValidity: ObjectValidity<T>[K],
    ) => {
        let newCount = invalidPropCount;

        if (!!modelValidity?.[prop] !== !!propValidity) {
            newCount = invalidPropCount + (propValidity ? 1 : -1);
            setInvalidPropCount(newCount);
            setModelValidity(
                modelValidity
                    ? {
                          ...modelValidity,
                          [prop]: propValidity,
                      }
                    : undefined,
            );
        }
        if (onValidityChange) {
            onValidityChange(newCount > 0 ? 'error' : undefined);
        }
    };

    function track<K extends keyof ObjectValidity<T>>(prop: K) {
        return {
            onValidityChange: (isInvalid: ObjectValidity<T>[K]) => handleItemValidityChange(prop, isInvalid),
            modelValidity:
                typeof upstreamModelValidity === 'object' ? upstreamModelValidity[prop] : modelValidity?.[prop],
        };
    }

    return {
        track,
        setItemValidity: handleItemValidityChange,
        setModelValidity: handleModelValidityChange,
        isInvalid: invalidPropCount > 0,
    };
}

export function useBinding<T extends object>({
    value: model,
    onChange: onModelChange,
    onValidityChange,
}: NullableBindingProps<T>) {
    const { track, isInvalid } = useErrors<T>({ onValidityChange });

    function bind<K extends keyof T & keyof ObjectValidity<T>>(prop: K): BindingProps<T[K]>;
    function bind<K extends keyof T & keyof ObjectValidity<T>, S>(
        prop: K,
        converter: (source: S) => T[K],
    ): NullableBindingProps<T[K], S>;
    function bind<K extends keyof T & keyof ObjectValidity<T>, S>(
        prop: K,
        converter?: (source: S) => T[K],
    ): NullableBindingProps<T[K], S> | NullableBindingProps<T[K]> {
        const {} = track<K>(prop);
        return {
            value: model?.[prop],
            onChange: (source: T[K] | S) => {
                const value = typeof converter === 'undefined' ? source : converter(source as S);
                model && onModelChange({ ...model, [prop]: value });
            },
        };
    }

    return { bind, isInvalid, track };
}

export type FormStatus = 'loading' | 'no-change' | 'changed' | 'invalid' | 'saving' | 'saved' | 'error';

export const FieldError = styled.div`
    color: red;
`;
export interface FormGroupProps<T> extends ValidityProps<T> {
    label?: ReactNode;
    children: ReactNode;
}

export function FormGroup<T>({ children, label, modelValidity }: FormGroupProps<T>) {
    return (
        <div className="form-group">
            {label !== undefined && <label className="control-label">{label}</label>}
            <div className="field-w">
                {children}
                {modelValidity && <FieldError>{modelValidity}</FieldError>}
            </div>
        </div>
    );
}
