import React, { FC } from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { FinancesPage } from './Finances';
import { CustomizePage } from './Customize';
import { Transcripts as StudentTranscripts } from './Student/Transcripts';
import { TemplateIndex } from './TranscriptTemplates';
import EditTemplate from './TranscriptTemplates/EditTemplate';

export const App: FC = () => {
    const location = useLocation();
    return (
        <TransitionGroup style={{ overflow: 'hidden' }}>
            <CSSTransition key={location.key} classNames="fade" timeout={400}>
                <Switch location={location}>
                    <Route path="/settings/newcustomize">
                        <CustomizePage />
                    </Route>
                    <Route path="/settings/templates">
                        <TemplateIndex />
                    </Route>
                    <Route
                        path="/templates/edit/:id?"
                        render={(props) => (
                            <EditTemplate
                                id={+(props.match.params.id || 0)}
                                cloneFrom={+(new URLSearchParams(props.location.search).get('cloneFrom') || 0)}
                            />
                        )}
                    />
                    <Route
                        path={`/students/finances/:id`}
                        render={(props) => (
                            <FinancesPage
                                tabId={new URLSearchParams(props.location.search).get('tabid')}
                                userId={+props.match.params.id}
                            />
                        )}
                    />
                    <Route path="/students/transcripts/:id">
                        {(props) => props.match && <StudentTranscripts studentId={+props.match.params.id} />}
                    </Route>
                    <Route>
                        <span>404</span>
                    </Route>
                </Switch>
            </CSSTransition>
        </TransitionGroup>
    );
};
