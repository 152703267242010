import React, { FC, FormEvent } from 'react';
import { CustomListEnum, CustomListItem, School } from '../api-client';
import { BindingProps, FormGroup, NullableBindingProps, useBinding, useErrors } from '../lib/Form';
import { RecursivePartial } from '../lib/util';
import { CustomList } from './CustomList';

interface EmailOptionsProps extends NullableBindingProps<School> {
    emailReplyTos: RecursivePartial<CustomListItem>[];
    onEmailReplyTosChange: (value: RecursivePartial<CustomListItem>[]) => void;
}
export const EmailOptions: FC<EmailOptionsProps> = ({
    value: model,
    onChange: onModelChange,
    emailReplyTos,
    onEmailReplyTosChange,
    onValidityChange,
    modelValidity,
}) => {
    const { track } = useErrors({ modelValidity, onValidityChange });
    return (
        <div className="form-section">
            <h4>
                SMTP Credentials{' '}
                <i
                    className="fa fa-question-circle"
                    title="Emails allow you to easily send emails to students and staff, using the data in your attendance system.  Set up the email credentials here in order for the email feature to work properly."
                ></i>
            </h4>
            <FormGroup label="SMTP Host" {...track('smtpHost')}>
                <input
                    type="text"
                    value={model?.smtpHost || ''}
                    onChange={(e) => model && onModelChange({ ...model, smtpHost: e.currentTarget.value })}
                />
            </FormGroup>
            <FormGroup label="SMTP Username" {...track('smtpUsername')}>
                <input
                    type="text"
                    value={model?.smtpUsername || ''}
                    onChange={(e) => model && onModelChange({ ...model, smtpUsername: e.currentTarget.value })}
                />{' '}
            </FormGroup>
            <FormGroup label="SMTP Password" {...track('smtpPassword')}>
                <input
                    type="password"
                    value={model?.smtpPassword || ''}
                    onChange={(e) => model && onModelChange({ ...model, smtpPassword: e.currentTarget.value })}
                />{' '}
            </FormGroup>
            <FormGroup label="SMTP From Address" {...track('smtpFrom')}>
                <input
                    type="text"
                    value={model?.smtpFrom || ''}
                    onChange={(e) => model && onModelChange({ ...model, smtpFrom: e.currentTarget.value })}
                />{' '}
            </FormGroup>

            <CustomList
                listName="Email Reply-Tos"
                tooltip={`\"Reply to addresses\" help you stay organized, in routing email replies to the appropriate school department.  Add \"reply to\" addresses here and they will then be available when composing emails and email templates.`}
                value={emailReplyTos}
                onChange={onEmailReplyTosChange}
            />

            <h4>
                Email Backups{' '}
                <i
                    className="fa fa-question-circle"
                    title='Save a copy of all emails sent out by the attendance system by "blind carbon copying" all of your emails to a unique email address.  This email address will not be visible in the emails you send out.  (The attendance system does not save copies of emails that you send out from the system).'
                ></i>
            </h4>
            <FormGroup label="Bcc All Emails (for backup) To" {...track('allEmailBcc')}>
                <input
                    type="text"
                    value={model?.allEmailBcc || ''}
                    onChange={(e) => model && onModelChange({ ...model, allEmailBcc: e.currentTarget.value })}
                />{' '}
            </FormGroup>
        </div>
    );
};
