import styled, { css } from 'styled-components';

export const Header = styled.header`
    padding: 2.5em 2.5em 0 2.5em;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: .4em;
`;

export const Title = styled.span`
    display: flex;
    align-items: center;
    gap: 1em;
    font-size: 1.6em;
    font-weight: bold;
`;

export const Footer = styled.div`
    border-top: var(--border);
    padding: 0.4em 1em;
    display: flex;
    gap: 0.4em;
    align-items: center;
`;

interface BodyProps {
    noPadding?: boolean;
}

export const Body = styled.div<BodyProps>`
    flex: 1;
    overflow: auto;
    ${(props) =>
        props.noPadding
            ? ''
            : css`
                  padding: 1em 2.5em;
              `}
`;

export const Page = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: hidden;

    &.fade-enter {
        opacity: 0;
        z-index: 1;
    }

    &.fade-enter.fade-enter-active {
        opacity: 1;
        transition: opacity 250ms ease-in;
    }
`;
