import { FC, useState } from 'react';
import AnimateHeight from 'react-animate-height';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import styled, { css } from 'styled-components';
import { RoundButton } from '../Button';

interface CommonProps {
    xAlignment?: 'left' | 'right';
    yAlignment?: 'top' | 'bottom';
}

interface DropdownMenuProps extends CommonProps {
    isOpen?: boolean;
}
const DropdownMenu = styled.div<DropdownMenuProps>`
    ${({ xAlignment }) =>
        xAlignment === 'left'
            ? css`
                  left: 100%;
              `
            : css`
                  right: 0;
              `}
    ${({ yAlignment }) =>
        yAlignment === 'bottom'
            ? css`
                  bottom: 100%;
              `
            : css`
                  top: 0;
              `}
    border: var(--border);
    border-radius: 10px;
    box-shadow: var(--shadow);
    background: white;
    position: absolute;
    background-color: white;
    z-index: 10;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);

    transition: opacity 250ms ease-in, transform 250ms ease-in;

    ${({ isOpen }) =>
        !isOpen
            ? css`
                  opacity: 0;
                  transform: translateY(-10px);
                  pointer-events: none;
              `
            : ''}
`;

const StyledActionsMenu = styled.div`
    justify-self: flex-end;
    flex: 0;
    position: relative;
`;

export interface ActionsMenuProps extends CommonProps {
    icon?: string;
}
export const ActionsMenu: FC<ActionsMenuProps> = ({ icon, children, ...props }) => {
    const [isOpen, setIsOpen] = useState(false);
    return (
        <StyledActionsMenu
            tabIndex={0}
            onKeyDown={(e) => e.key === 'Enter' && setIsOpen(!isOpen)}
            onBlur={({ currentTarget, relatedTarget }) =>
                !(relatedTarget instanceof Node && currentTarget.contains(relatedTarget)) && setIsOpen(false)
            }
        >
            <RoundButton
                className={`fa fa-${icon || 'ellipsis-v'}`}
                onClick={(e) => {
                    e.stopPropagation();
                    setIsOpen((x) => !x);
                }}
            />
            <DropdownMenu {...props} isOpen={isOpen} className="menu">
                {children}
            </DropdownMenu>
        </StyledActionsMenu>
    );
};
