import React, { FC, useEffect, useState } from 'react';
import { Link, Route, Switch, useLocation, useRouteMatch } from 'react-router-dom';
import { _propertyEquals, ExprView, _and } from '../lib/Table/Filters';
import { Api, CustomListEnum, ExpressionNode, TransactionGroup, TransactionType, useApi } from '../api-client';
import { ActionsMenu } from '../lib/ActionsMenu/ActionsMenu';
import { Button } from '../lib/Button';
import { Body, Footer, Header, Page, Title } from '../lib/Page';
import { Column, ColumnOf, Types } from '../lib/Table';
import TableRow from '../lib/Table/TableRow';
import TH from '../lib/Table/TH';
import { TransactionGroupForm } from './TransactionGroupForm';
import { Spinner } from '../lib/Spinner';
import styled from 'styled-components';

const StyledFilterRow = styled.div`
    display: flex;
    gap: 1em;
`;
interface FinancesPageProps {
    userId: number;
    tabId: string | null;
}
export const FinancesPage: FC<FinancesPageProps> = ({ userId, tabId }) => {
    const { search: query } = useLocation();
    const { path, url } = useRouteMatch();
    const [model, setModel] = useState<TransactionGroup[]>([]);
    const [filter, _setFilter] = useState<ExpressionNode | undefined>(DEFAULT_FILTER);
    const [isLoading, setLoading] = useState<'loading' | 'error' | 'filter changed' | 'ready'>('loading');

    function setFilter(x: ExpressionNode | undefined) {
        setLoading('filter changed');
        _setFilter(x);
    }

    const { fetchData } = useApi(
        async (api, params) => {
            setLoading('loading');
            const { data, error } = await api.transactions.listTransactionGroups(
                { transacteeId: userId, filter },
                params,
            );
            if (error) setLoading('error');
            else {
                setModel(data);
                setLoading('ready');
            }
        },
        [userId],
    );

    const columns: ColumnOf<TransactionGroup>[] = [
        { title: 'ID', name: 'id', type: Types.number },
        { title: 'Entry Date', name: 'entryDate', type: Types.date },
        { title: 'Due Date', name: 'dueDate', type: Types.date },
        { title: 'Amount', name: 'amount', type: Types.decimal },
    ];

    return (
        <Switch>
            <Route exact path={path}>
                <Page>
                    <Header>
                        <Title>
                            Finances{' '}
                            {isLoading == 'loading' ? (
                                <Spinner />
                            ) : isLoading === 'error' ? (
                                <i className="fa fa-exclamation-triangle" />
                            ) : null}
                        </Title>
                    </Header>
                    <Body>
                        <StyledFilterRow>
                            {filter && <ExprView param={filter} onParameterChange={setFilter} />}
                        </StyledFilterRow>
                        <div>
                            <Button
                                className="fa fa-check"
                                disabled={isLoading != 'filter changed'}
                                onClick={() => fetchData()}
                            />
                        </div>
                        <table className="table">
                            <thead>
                                <tr>
                                    {columns.map((x) => (
                                        <TH
                                            key={x.name}
                                            column={x}
                                            onClick={() => {
                                                const xpr = _propertyEquals(x, 'SchoolLogic.DB.TransactionGroup');
                                                setFilter(filter ? _and(filter, xpr) : xpr);
                                            }}
                                        />
                                    ))}
                                </tr>
                            </thead>
                            <tbody>
                                {model.map((x) => (
                                    <TableRow
                                        key={x.id}
                                        columns={columns}
                                        record={x}
                                        actions={
                                            <Button
                                                as={Link}
                                                to={`${url}invoice/${x.id}${query}`}
                                                className="fa fa-pencil-alt"
                                            />
                                        }
                                    />
                                ))}
                            </tbody>
                        </table>
                        <p>Total: {model.reduce((a, b) => a + (b.amount || 0), 0)}</p>
                    </Body>
                    <Footer>
                        <ActionsMenu icon="plus" yAlignment="bottom" xAlignment="left">
                            <Link to={`${url}invoice${query}`}>Invoice</Link>
                            <Link to={`${url}credit${query}`}>Credit</Link>
                            <Link to={`${url}payment${query}`}>Payment</Link>
                            <Link to={`${url}pledge${query}`}>Pledge</Link>
                            <Link to={`${url}donation${query}`}>Donation</Link>
                        </ActionsMenu>
                    </Footer>
                </Page>
            </Route>
            <Route path={`${path}/invoice/:id?`}>
                {({ match }) => (
                    <TransactionGroupForm
                        userId={userId}
                        invoiceNumber={+(match?.params.id || 0)}
                        transactionType={TransactionType.Invoice}
                    />
                )}
            </Route>
            <Route path={`${path}/credit/:id?`}>
                {({ match }) => (
                    <TransactionGroupForm
                        userId={userId}
                        invoiceNumber={+(match?.params.id || 0)}
                        transactionType={TransactionType.Credit}
                    />
                )}
            </Route>
            <Route path={`${path}/payment/:id?`}>
                {({ match }) => (
                    <TransactionGroupForm
                        userId={userId}
                        invoiceNumber={+(match?.params.id || 0)}
                        transactionType={TransactionType.Payment}
                    />
                )}
            </Route>
            <Route path={`${path}/pledge/:id?`}>
                {({ match }) => (
                    <TransactionGroupForm
                        userId={userId}
                        invoiceNumber={+(match?.params.id || 0)}
                        transactionType={TransactionType.Pledge}
                    />
                )}
            </Route>
            <Route path={`${path}/donation/:id?`}>
                {({ match }) => (
                    <TransactionGroupForm
                        userId={userId}
                        invoiceNumber={+(match?.params.id || 0)}
                        transactionType={TransactionType.Donation}
                    />
                )}
            </Route>
        </Switch>
    );
};

const DEFAULT_FILTER: ExpressionNode = {
    typeName: 'System.Boolean',
    methodName: 'AndAlso',
    parameters: [
        {
            typeName: 'System.Boolean',
            methodName: 'GreaterThan',
            parameters: [
                {
                    typeName: 'System.DateTime',
                    literalValueJson: '"DueDate"',
                    methodName: 'Property',
                    parameters: [{ typeName: 'SchoolLogic.DB.TransactionGroup', methodName: 'Parameter' }],
                },
                { typeName: 'System.DateTime', literalValueJson: '"2021-01-01T00:00:00"', methodName: 'Constant' },
            ],
        },
        {
            typeName: 'System.Boolean',
            methodName: 'LessThan',
            parameters: [
                {
                    typeName: 'System.DateTime',
                    literalValueJson: '"DueDate"',
                    methodName: 'Property',
                    parameters: [{ typeName: 'SchoolLogic.DB.TransactionGroup', methodName: 'Parameter' }],
                },
                { typeName: 'System.DateTime', literalValueJson: '"2022-01-01T00:00:00"', methodName: 'Constant' },
            ],
        },
    ],
};
