import React, { FC, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { TemplateIndexDTO, Api, TemplatesIndexModel, useApi } from '../api-client';
import { ActionsMenu } from '../lib/ActionsMenu/ActionsMenu';
import { Button, RoundButton } from '../lib/Button';
import { Body, Footer, Header, Page, Title } from '../lib/Page';
import { Spinner } from '../lib/Spinner';

const TemplateIconMenu = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const StyledTemplateIcon = styled.figure`
    padding: 0.5em;
    margin: 0;
    border-radius: 10px;
    border: solid 3px var(--primary-color);
    text-align: center;
`;

const TemplateImage = styled.img`
    height: 8em;
`;

interface TemplateIconProps {
    model: TemplateIndexDTO;
    onModelChange?: (model: TemplateIndexDTO) => void;
    isPreset?: boolean;
}

const TemplateIcon: FC<TemplateIconProps> = ({ model: template, onModelChange: setModel, isPreset }) => {
    const onToggleFavorite = () => {
        const isFavorite = !template.isFavorite;
        Api.templates.setFavorite(template.id, { isFavorite }).catch(() => alert('failed to save favorite'));
        setModel?.({ ...template, isFavorite: isFavorite });
    };
    return (
        <StyledTemplateIcon key={template.id}>
            {!isPreset ? (
                <TemplateIconMenu>
                    <RoundButton
                        className={`${template.isFavorite ? 'fas' : 'far'} fa-star`}
                        onClick={onToggleFavorite}
                    />
                    <ActionsMenu>
                        <Link to={`/templates/edit/${template.id}`}>
                            <i className="fa fa-edit"></i>
                            <span>Edit</span>
                        </Link>
                        <Link to={`/templates/edit?cloneFrom=${template.id}`}>
                            <i className="fa fa-clone"></i>
                            <span>Duplicate</span>
                        </Link>
                    </ActionsMenu>
                </TemplateIconMenu>
            ) : null}
            <Link to={isPreset ? `/templates/edit?cloneFrom=${template.id}` : `/templates/edit/${template.id}`}>
                <TemplateImage src={`/uploads/template-thumbnails/${template.thumbnail || 'default.jpg'}`} />
                <figcaption>{template.name}</figcaption>
            </Link>
        </StyledTemplateIcon>
    );
};

const TemplatesGrid = styled.div`
    display: grid;
    gap: 0.7em;
    grid-template-columns: repeat(auto-fit, minmax(10em, 17em));
    margin: 1em 0;
`;
export const TemplateIndex: FC = () => {
    const [model, setModel] = useState<TemplatesIndexModel>({ presets: [], templates: [] });
    const [isLoading, setIsLoading] = useState(true);

    useApi(async (api, params) => {
        const { data: model } = await api.settings.templates(params);
        setModel(model);
        setIsLoading(false);
    }, []);

    return (
        <Page>
            <Header>
                <Title>
                    <>Templates {isLoading ? <Spinner /> : null}</>
                </Title>
            </Header>
            <Body>
                <h4>Presets</h4>
                <TemplatesGrid>
                    {model.presets.map((template) => (
                        <TemplateIcon key={template.id} model={template} isPreset />
                    ))}
                </TemplatesGrid>
                <h4>Templates</h4>
                <TemplatesGrid>
                    {model.templates
                        .sort((a, b) => (a.isFavorite === b.isFavorite ? 0 : a.isFavorite ? -1 : 1)) // sort by "isFavorite"
                        .map((template, i) => (
                            <TemplateIcon
                                key={template.id}
                                model={template}
                                onModelChange={(template) =>
                                    setModel({
                                        ...model,
                                        templates: Object.assign([...model.templates], {
                                            [i]: template,
                                        }),
                                    })
                                }
                            />
                        ))}
                </TemplatesGrid>
            </Body>
            <Footer>
                <Button large mode="primary" as="a" href="/templates/edit">
                    <i className="fa fa-plus" />
                    New Template
                </Button>
            </Footer>
        </Page>
    );
};
