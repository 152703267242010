import React, { FC, ReactElement, ReactNode, useEffect, useMemo, useState } from 'react';
import {
    CustomFieldType,
    FieldSection,
    CustomField,
    IDName,
    LookupTypeEnum,
    CustomFieldOpt,
    CustomSectionDTO,
    CustomFieldDTO,
    useApi,
    Lookup,
} from '../api-client';
import { FieldError } from './Form';
import { Spinner } from './Spinner';

interface LookupFieldPropsProps extends FieldProps {
    lookupType: LookupTypeEnum;
}

function LookupField({ lookupType, field, ...props }: LookupFieldPropsProps) {
    const [opts, setOpts] = useState<Lookup[]>();

    useApi(
        async (api, props) => {
            const { data } = await api.lookup.getLookup({ type: lookupType }, props);
            setOpts(data);
        },
        [lookupType],
    );

    return opts === undefined ? (
        <Spinner />
    ) : (
        <Field
            {...props}
            field={{
                ...field,
                type: CustomFieldType.Dropdown,
                options: opts?.map((x) => ({
                    fieldID: field.id,
                    id: x.id,
                    name: x.value,
                    orderBy: x.orderBy || 0,
                })),
            }}
        />
    );
}

function classFor(type: CustomFieldType): string {
    return type == CustomFieldType.DatePicker
        ? 'date-picker'
        : type == CustomFieldType.NumberField
        ? 'number-input'
        : type == CustomFieldType.TimePicker
        ? 'time-picker'
        : type == CustomFieldType.DecimalInput
        ? 'decimal-input'
        : '';
}

interface FieldProps {
    field: CustomFieldDTO;
    value: string | undefined;
    disabled?: boolean;
}

const Field: React.FunctionComponent<FieldProps> = (props) => {
    const { field, value: val, disabled } = props;
    const name = `FieldVals[${field.id}].Val`;
    switch (field.type) {
        case CustomFieldType.TextField:
        case CustomFieldType.DatePicker:
        case CustomFieldType.NumberField:
        case CustomFieldType.DecimalInput:
        case CustomFieldType.TimePicker:
            return (
                <input
                    type="text"
                    name={name}
                    defaultValue={val}
                    className={classFor(field.type)}
                    disabled={disabled}
                />
            );
        case CustomFieldType.NotesField:
            return (
                <textarea
                    name={name}
                    rows={3}
                    defaultValue={val}
                    className={classFor(field.type)}
                    disabled={disabled}
                />
            );
        case CustomFieldType.Enrollment:
        case CustomFieldType.Dropdown:
            return (
                <select name={name} className="cust-select" defaultValue={val} disabled={disabled}>
                    <option value="">-- Select --</option>
                    {field.options?.map((opt) => (
                        <option key={opt.id} value={opt.id}>
                            {opt.name}
                        </option>
                    ))}
                </select>
            );
        case CustomFieldType.MultiDropdown:
            return (
                <>
                    <select
                        multiple
                        data-placeholder={'Choose Options'}
                        className="cust-select"
                        defaultValue={val}
                        disabled={disabled}
                    >
                        <option value="">-- Select --</option>
                        {field.options?.map((opt) => (
                            <option key={opt.id} value={opt.id}>
                                {opt.name}
                            </option>
                        ))}
                    </select>
                    <input type="hidden" name={name} defaultValue={val} className="cust-select-hidden" />;
                </>
            );
        case CustomFieldType.Country:
            return <LookupField lookupType={LookupTypeEnum.Countries} {...props} />;
        case CustomFieldType.State: {
            return <LookupField lookupType={LookupTypeEnum.States} {...props} />;
        }
        case CustomFieldType.Radios:
            return (
                <div className="radios">
                    {field.options?.map((opt) => (
                        <label key={opt.id}>
                            <input
                                type="radio"
                                value={opt.id}
                                name={name}
                                checked={val === opt.id.toString()}
                                disabled={disabled}
                            />{' '}
                            {opt.name}
                        </label>
                    ))}
                </div>
            );

        case CustomFieldType.Attachment:
            return <input type="hidden" name={name} className="attachment" defaultValue={val} disabled={disabled} />;
        case CustomFieldType.PhoneNums:
        case CustomFieldType.Emails:
            return (
                <div
                    className="custom-list-grp"
                    data-type={field.type == CustomFieldType.PhoneNums ? 'phone-nums' : 'emails'}
                    data-id={field.id}
                >
                    <input type="hidden" name={name} className="" defaultValue={val} disabled={disabled} />
                </div>
            );
        case CustomFieldType.Toggle:
            return <input type="hidden" className="toggle-field" name={name} defaultValue={val} disabled={disabled} />;
        case CustomFieldType.Formula:
            return <input type="text" name={name} disabled />;
        default:
            return <FieldError>'Field not supported'</FieldError>;
    }
};

export interface CustomSectionProps {
    section: CustomSectionDTO;
    fieldValues?: { [fieldId: number]: string | undefined };
    fieldErrors?: { [fieldId: number]: string | undefined };
}

export function CustomSection({ section: sect, fieldValues, fieldErrors }: CustomSectionProps) {
    var fields = sect.fields;
    return (
        <div className={`form-section ${sect.hasEditPermission ? '' : 'disabled-field-section'}`}>
            <h3>{sect.name}</h3>
            {fields?.map((field) => {
                const val = fieldValues?.[field.id];
                const err = fieldErrors?.[field.id];
                return (
                    <div
                        key={field.id}
                        className={`form-group ${
                            field.type == CustomFieldType.Emails || field.type == CustomFieldType.PhoneNums
                                ? 'align-top'
                                : ''
                        }`}
                    >
                        <label className="control-label">
                            {field.name}

                            {field.helpText && (
                                <span className="tooltip fa fa-question-circle" title={field.helpText}></span>
                            )}
                        </label>
                        <div className="field-w">
                            <input type="hidden" name="FieldVals.Index" value={field.id} />{' '}
                            {/*since some fields are disabled so the IXs are not consecutive */}
                            <Field field={field} value={val} disabled={!sect.hasEditPermission} />
                            <input type="hidden" name={`FieldVals[${field.id}].FieldID`} value={field.id} />
                            {err && <label className="error">{err}</label>}
                        </div>
                    </div>
                );
                field.id++;
            })}
        </div>
    );
}
