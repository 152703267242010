import React, { FC } from 'react';
import { FormStatus } from '.';
import { Button, ButtonProps } from '../Button';
import { Spinner } from '../Spinner';

export interface FormFooterProps {
    formStatus: FormStatus;
}
export const SaveButton: FC<FormFooterProps> = ({ formStatus }) =>
    formStatus === 'saving' ? (
        <Button large type="submit" disabled>
            <Spinner /> Saving
        </Button>
    ) : formStatus === 'saved' ? (
        <Button large type="submit" mode="success" disabled>
            <i className="fa fa-check" /> Saved
        </Button>
    ) : formStatus === 'error' ? (
        <Button large type="submit" mode="failure" disabled>
            <i className="fa fa-exclamation-triangle" /> Save Failed
        </Button>
    ) : formStatus === 'invalid' ? (
        <Button large type="submit" mode="failure" disabled>
            <i className="fa fa-exclamation-triangle" /> Invalid
        </Button>
    ) : (
        <Button large type="submit" mode="primary" disabled={formStatus != 'changed'}>
            Save
        </Button>
    );
