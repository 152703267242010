/**
 * Common print paper sizes in points [width, height].
 */
export const paperSizes = {
    'A3 Portrait': [842, 1190],
    'A4 Portrait': [595, 842],
    'A5 Portrait': [420, 595],
    'Letter Portrait': [612, 792],
    'Legal Portrait': [612, 1008],
    Tabloid: [1224, 792],
    'A3 Landscape': [1190, 842],
    'A4 Landscape': [842, 595],
    'A5 Landscape': [595, 420],
    'Letter Landscape': [792, 612],
    'Legal Landscape': [1008, 612],
    Ledger: [792, 1224],
};

export type RecursivePartial<T, Except extends keyof T = never> =
    T extends (infer ElementType)[] ?
        RecursivePartial<ElementType>[] :
    {
        [P in keyof T]?: P extends Except ? T[P] : RecursivePartial<T[P]>;
    };
